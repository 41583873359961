//== Colors
$gray-base:             #363d48;

$brand-primary:         #0700a4;
$brand-primary-2:       #2803ff;
$brand-primary-3:       #00ffff;
$brand-success:         #399300;
$brand-info:            #65b3e3;
$brand-warning:         #ff7b4d;
$brand-danger:          #ff626d;

$color-dark-grey:       #303743;
$color-light-grey:      #b8b8b8;

$bg-color-grey:               #dedede;
$bg-color-light-grey:         #f7f7f7;
$border-color-grey:           #d6d8da;
$border-color-light-grey:     #edeeef;
$color-dark-blue: #41647c;
$color-light-green: #90d615;


//== Scaffolding

//** Background color for `<body>`.
$body-bg:               #fff;
//** Global text color on `<body>`.
$text-color:            $gray-base;
$text-light-color:      $color-light-grey;

//** Global textual link color.
$link-color:            $brand-primary;
//** Link hover color set via `darken()` function.
$link-hover-color:      $brand-primary-2;
//** Link hover decoration.
$link-hover-decoration: none;

//== Typography

$font-family-sans-serif:  'Roboto', Arial, sans-serif;
$font-family-monospace:   'Droid Sans Mono', monospace;
$font-size-base:          13px;
$font-weight-base:        300;
$font-size-h1:            24px;
$font-size-h2:            18px;
$font-size-h3:            16px;
$font-size-h4:            14px;
$line-height-base:        1.4;
$headings-color:          #303743;

//== Buttons

$btn-font-weight:                600;

$btn-light-color:              #303743;
$btn-default-bg:                 #fff;
$btn-default-border:             #d6d8da;
$btn-default-hover-bg:           #dedede;

$btn-light-color:              #303743;
$btn-light-bg:                 #e9e3de;
$btn-light-border:             #d3cbc3;
$btn-light-hover-bg:           #f4f4f4;

$btn-primary-color:              #fff;
$btn-primary-bg:                 $brand-primary;
$btn-primary-border:             $brand-primary;
$btn-primary-hover-bg:           $brand-primary-2;

$btn-success-color:              #fff;
$btn-success-bg:                 #399300;
$btn-success-border:             #399300;
$btn-success-hover-bg:           #74b34c;

$btn-info-color:                 #fff;
$btn-info-bg:                    #65b3e3;
$btn-info-border:                #65b3e3;
$btn-info-hover-bg:              #93caeb;

$btn-warning-color:              #fff;
$btn-warning-bg:                 #ef7d00;
$btn-warning-border:             #ef7d00;
$btn-warning-hover-bg:           #f4a44c;

$btn-danger-color:               #fff;
$btn-danger-bg:                  #f16647;
$btn-danger-border:              #f16647;
$btn-danger-hover-bg:           #f5947e;

$btn-link-disabled-color:        #b9b9b9;
$btn-inverse-bg:                 transparent;

$btn-inverse-primary-color:      $brand-primary;
$btn-inverse-success-color:      #399300;
$btn-inverse-info-color:         #65b3e3;
$btn-inverse-warning-color:      #ef7d00;
$btn-inverse-danger-color:       #f16647;

//== Tables
//
//## Customizes the `.table` component with basic values, each used across all table variations.

//** Padding for `<th>`s and `<td>`s.
$table-cell-padding:            8px;
//** Padding for cells in `.table-condensed`.
$table-condensed-cell-padding:  5px;

//** Default background color used for all tables.
$table-bg:                      #fff;
//** Background color used for `.table-striped`.
$table-bg-accent:               #fff;
$table-bg-even:                 $bg-color-light-grey;
//** Background color used for `.table-hover`.
$table-bg-hover:                #dedede;
$table-bg-active:               $table-bg-hover;

//** Border color for table and cell borders.
$table-border-color:            $border-color-grey;
$table-thead-bg:                #dedede;

//== Form states and alerts
//
//## Define colors for form feedback states and, by default, alerts.

$state-success-text:             #189771 !default;
$state-success-bg:               #c7f2e5 !default;
$state-success-border:           darken(adjust-hue($state-success-bg, -10), 5%) !default;

$state-info-text:                #005cbf !default;
$state-info-bg:                  #bfdeff !default;
$state-info-border:              darken(adjust-hue($state-info-bg, -10), 7%) !default;

$state-warning-text:             #be5f0f !default;
$state-warning-bg:               #ffdfc4 !default;
$state-warning-border:           darken(adjust-hue($state-warning-bg, -10), 5%) !default;

$state-danger-text:              #ae2f69 !default;
$state-danger-bg:                #f9cfe2 !default;
$state-danger-border:            darken(adjust-hue($state-danger-bg, -10), 5%) !default;

// Forms

$input-height-base: 32px;
$input-color: $text-color;
$input-bg: #fff;
$input-border: $border-color-grey;
$input-border-radius: 3px;
$input-bg-disabled: $bg-color-light-grey;
$padding-base-vertical: 5px;
$padding-base-horizontal: 15px;

// Tabs
$nav-tabs-border-color: $border-color-grey;
$nav-tabs-active-link-hover-color: #555;
$nav-tabs-active-link-hover-bg: $bg-color-light-grey;
$nav-tabs-active-link-hover-border-color: $border-color-grey;

// Pagination
$pagination-border: $border-color-grey;
$pagination-hover-color: $btn-primary-color;
$pagination-hover-bg: $btn-primary-hover-bg;
$pagination-hover-border: $btn-primary-border;
$pagination-active-color: $btn-primary-color;
$pagination-active-bg: $btn-primary-bg;
$pagination-active-border: $btn-primary-border;
$pagination-disabled-color: $text-light-color;
$pagination-disabled-bg: $btn-default-hover-bg;
$pagination-disabled-border: $btn-default-border;

$border-radius-base: 3px;

$state-danger-text: $brand-danger;