
$screen-md-min: 992px;

.api-page{
    .sidebar-nav{
        padding: 0;
        border-top: 0;
    }

    .menu li > a{
        padding: 10px 50px 10px 15px;
    }

    .menu a > i{
        left: auto;
        right: 15px;
    }

    .menu .sub-menu{
        list-style: none;
        border-top: 1px solid #d6d8da;
        border-bottom: 1px solid #d6d8da;

        a{
            margin-left: -45px;
            padding-left: 45px;
        }
    }

    .panel-group {
        margin: 0 -15px 30px;

        .panel{
            background: none;
            border: 0;
            box-shadow: none;
            border-radius: 0;
        }

        .panel + .panel{
            margin-top: 0;
        }
    }

    .panel-heading{
        font-size: 13px;
        line-height: 20px;
        font-weight: 400;
        padding: 0;
        border-top-right-radius: 0;
        border-top-left-radius: 0;

        a{
            display: block;
            padding: 10px 55px 10px 15px;
            color: #7d7d7d;
            background: #fff;
            position: relative;
            transition: all .25s;

            &.collapsed{
                color: #333;
                background: #f5f5f5;
                text-decoration: none;
            }

            &:hover{
                color: #363d48;
                text-decoration: none;
                background: #dedede;
            }
        }

        i{
            width: 24px;
            font-size: 20px;
            line-height: 24px;
            position: absolute;
            top: 50%;
            right: 15px;
            margin-top: -12px;
            text-align: center;
            color: #888;
        }
    }

    .panel-body{
        border: none;
        ul{
            padding-left: 30px;
        }
    }

    .content{
        max-width: 100%;
        flex: 1;
    }

    #app{
        min-height: 100%;
        display: flex;
    }

    .content-wrap{
         flex: 1;
         display: flex;
    }

    .aside{
        background: #263238;
        border-left: 1px solid #d6d8da;
        color: #fff;

        h1, h2, h3, h4, h5, h6{
            color: #65b3e3;
        }
    }

    .aside-wrap{
        padding: 30px;
    }
}

#app {
    .api-sidebar-left {
        padding: 60px 15px 50px;

        .path-list {
            list-style: none;
            padding-left: 0;

            .path-item {
                padding: 5px;
                font-size: 13px;
                cursor: pointer;

                a {
                    color: rgba(38, 50, 56, 0.9);
                }
            }
        }

        .model-list {
            list-style: none;
            padding-left: 0;

            .model-item {
                padding: 5px;
                font-size: 13px;

                a {
                    color: rgba(38, 50, 56, 0.9)
                }
            }

            .model-item:hover {
                cursor: pointer;
            }
        }
    }

    .tag-list {
        .tag-item {
            font-size: 14px;
        }
    }

    .tag-item{
        > i:before{
            content: "\f107";
        }

        &.collapsed{
            > i:before{
                content: "\f105";
            }
        }
    } 

    .panel-group .panel-heading + .panel-collapse > .panel-body {
        border-top: none;
    }

    .api-doc-container {
        padding: 0;
    }

    .method-area {
        display: flex;

        .method-copy, .method-example {
            padding: 15px 15px;

            .method-title {
                color: $brand-primary;

                span {
                  font-size: 16px;
                  color: #363d48;
                }
            }

            .request-method-copy {
                color: #363d48;
            }

            p {
                font-size: 15px;
            }
        }

        .method-attributes-container {
            .attribute-header {
                font-size: 16px;
                text-transform: uppercase;
                border-bottom: 1px solid #eee;
                color: #bbb;
            }

            .attribute-list {
                list-style: none;
                padding-left: 0;

                .attribute-item {
                    padding-top: 15px;
                }


                .attribute-item:first-child {
                    padding-top: 0;
                }

                .attr-name {
                    font-size: 15px;
                }
            }
        }

        .method-response-container {
            .method-response-header {
                font-size: 18px;
                padding-top: 20px;
            }

            .method-response-list {
                list-style: none;
                padding-left: 0;
            }
        }
    }

    .models-area {
        display: flex;

        .model-copy, .model-example {
            padding: 25px 15px;

            .model-item-title {
                color: #363d48;
                margin-bottom: 15px;
            }

            .model-title {
                color: $brand-primary;

                span {
                    font-size: 16px;
                    color: #363d48;
                }
            }
        }

        .model-attributes-header {
            font-size: 16px;
            text-transform: uppercase;
            border-bottom: 1px solid #eee;
            color: #bbb;
        }

        .property-list {
            list-style: none;
            padding-left: 0;
            padding-bottom: 0;
            margin-bottom: 0;

            .property-item {
                padding: 5px 0;
                font-size: 14px;

                .property-name {
                    font-weight: bold;
                    word-break: break-all
                }

                ul {
                    list-style: none;
                }

                p {
                    margin-bottom: 0;
                }

                .has-children{
                    .attr-name{
                        cursor: pointer;
                    }
                }
    
            }

            .property-item:first-child {
                padding-top: 0;
            }

            .property-item:last-child {
                padding-bottom: 0;
            }
        }
    }

    .method-example-route,
    .method-example-request-sample,
    .model-attributes-sample,
    .api-endpoint .route-path {
        background-color: #20292d;
        padding: 10px 15px;
    }

    .api-endpoint .route-path h1 {

    }

    .api-endpoint .route-path p {
        margin-bottom: 0;
    }

    .method-example-request-sample .value {
        font-weight: 500;
    }

    .model-attributes-sample {
        ul {
            list-style: none;
            margin-bottom: 0;

            li .key {
                font-weight: 500;
            }
        }

        p {
            margin-bottom: 0;
        }

        .sublist {
            display: none;
        }

        span {
            font-size: 14px;
            font-weight: 500;
        }
    }

    .method-example-route {

        line-height: 2;

        .method-type {
            text-transform: uppercase;
            padding: 5px;
        }

        .method-post {
            background-color: #1E90FF;
        }

        .method-get {
            background-color: #399300;
        }

        .method-put {
            background-color: #fff000;
        }

        .method-delete {
            background-color: #ff0000;
        }

        .method-route-path {
            padding: 0 10px;
        }

        .method-request-sample-trigger {
            .fa {
                cursor: pointer;
                font-size: 22px;
                color: #3091c9;
            }
        }
    }

    .attribute-list-trigger-show {
      cursor: pointer;
    }

    .attribute-list-trigger-hide {
      cursor: pointer;
    }

    .request-sample-header {
        font-weight: 100;
        text-transform: uppercase;
        margin-bottom: 0;
        padding: 15px 0;
        font-size: 15px;
    }

    .method-example-request-sample p {
        margin-bottom: 0;
    }

    .method-example-request-sample ul {
        list-style: none;
        margin-bottom: 0;

        li .key {
            font-weight: 500;
        }
    }
}

.attribute-model-trigger-show {
    content: url("/img/ico-sort-asc.png");
    position: relative;
    bottom: -7px;
}

.attribute-model-trigger-hide {
    content: url("/img/ico-sort-desc.png");
    position: relative;
    bottom: -7px
}

.request-route-path {
    padding: 10px;
    background-color: #fff;
    border-radius: 0 0 3px 3px;
    display: none;
}

.method-response-list {
    .alert {
        font-weight: 500;
        border: none;
        border-radius: 0;
        margin-bottom: 5px;
        padding: 10px;
    }
}

.required-param {
    color: #ff0000;
    font-weight: 500;
}

.api-sidebar-left {
    .path-list {
        .method-post {
            width: 20px;
            color: #fff;
            padding: 3px;
            border-radius: 3px;
            background-color: #1E90FF;
            font-size: 10px;
            text-transform: uppercase;
        }

        .method-get {
            color: #fff;
            padding: 3px;
            border-radius: 3px;
            background-color: #399300;
            font-size: 10px;
            text-transform: uppercase;
        }

        .method-put {
            color: #fff;
            padding: 3px;
            border-radius: 3px;
            background-color: #fff000;
            font-size: 10px;
            text-transform: uppercase;
        }

        .method-delete {
            color: #fff;
            padding: 3px;
            border-radius: 3px;
            background-color: #ff0000;
            font-size: 10px;
            text-transform: uppercase;
        }

        .method-type, .method-name {
            display: block;
            width: 40px;
            margin-right: 10px;
            text-align: center;
            white-space: nowrap;
        }
    }
}

.method-route-path {
    font-weight: 500;
}

.value-string {
    color: #66B16E;
}

.value-integer {
    color: #4A8BB3;
}

.value-boolean, .value-true {
    color: firebrick
}

.attr-description {
    line-height: 2;
}

.border-bottom {
    border-bottom: 1px solid rgba(68, 82, 93, 0.54);
    padding-bottom: 5px;
}

.property-list .sublist {
    padding: 10px;
    background: #eee;
    display: none
}

.property-item .row {
    line-height: 2;
}

.api-doc-client-container {

    .sidebar-api-client,
    .panel-heading a.collapsed {
        background-color: #fff;
    }

    .sidebar-wrap {
        padding-top: 10px !important;
    }

    .method-list {
        border-left: 1px solid #d6d8da;
    }

    .api-client-content{
        padding-left: 250px;
    }

    .api-client-nav {
        width: 250px;
        position: absolute;

        &.nav-fixed{
            position: fixed;
            top: 60px;
        }
    }
}

.api-client-page-heading {
    padding: 30px 30px 15px;
    // border-bottom: 1px solid #eee;
}

@media only screen and (max-width: $screen-md-min) {
    .models-area, .method-area {
        display: block !important;
    }

    .request-method-copy .method-title {
        margin-top: 15px;
    }

    .model-example {
        padding: 15px !important;
    }

    .api-page .aside {
        border-left: none;
    }

    .method-example h1 {
        margin-bottom: 0;
    }

    .model-example-head {
        display: none;
    }
}

.api-banner{
    border-top: 1px solid #eee;
    border-bottom: 1px solid #eee;
    padding: 30px 15px;
    font-size: 15px;

    p{
        margin-bottom: 30px;
    }

    img{
        max-width: 600px;
        width: 100%;
    }
}
