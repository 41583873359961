/* custom-table */

.table{
  margin-bottom: 20px;
  font-size: 12px;
  border: 1px solid $table-border-color;

  // Cells
  > thead,
  > tbody,
  > tfoot {
    > tr {
      > th,
      > td {
        padding: $table-cell-padding;
        line-height: 1.7;
        vertical-align: middle;
        border: 0;
        border-left: 1px solid $table-border-color;

        &:first-child{
          border-left: 0;
        }
      }
    }
  }

  > thead > tr > th {
    background: $table-thead-bg;
    color: $headings-color;
    font-weight: 400;
  }

  .th-cta,
  .td-cta{
    width: 100px;
    white-space: nowrap;

    .btn{
      white-space: nowrap;
    }
  }
  .th-flag,
  .td-flag{
    width: 55px;
    white-space: nowrap;
  }
}

.table-no-border{
  // Cells
  > thead,
  > tbody,
  > tfoot {
    > tr {
      > th,
      > td {
        border-left: 0;
      }
    }
  }
}

.table-striped {
  > tbody > tr:nth-of-type(odd) {
    background-color: $table-bg-accent;
  }

  > tbody > tr:nth-of-type(even) {
    background-color: $table-bg-even;
  }
}

.table-hover {
  > tbody > tr:hover {
    background-color: $table-bg-hover;
  }
}

.table-responsive{
  margin-bottom: 20px;
  border: 1px solid $table-border-color;

  .table{
    margin-bottom: 0;
    border: 0;
  }
}

/* tables with sorting */
.tablesorter {
  tr .theader {
    padding-right: 25px;
    background-image: url('/img/ico-sort.png');
    background-repeat: no-repeat;
    background-position: center right;
    cursor: pointer;
  }
  tr .th-sortable {
    padding: 0;
    a{
      display: block;
      padding: 8px 24px 8px 8px;
    }
  }
  tr .theaderSortUp {
      background-image: url('/img/ico-sort-asc.png');
  }
  tr .theaderSortDown {
      background-image: url('/img/ico-sort-desc.png');
  }
  tr .sorter-false {
      background-image: none;
      cursor: default;
  }
  th{
    white-space: nowrap;
    a{
      color: #303743;
    }
  }
}


table.tablesorter thead tr .theaderSortUp {
  background-image: url('/img/ico-sort-asc.png');
}
table.tablesorter thead tr .theaderSortDown {
  background-image: url('/img/ico-sort-desc.png');
}
/*table.tablesorter thead tr .headerSortDown,
  table.tablesorter thead tr .headerSortUp {
    background-color: rgba(52, 150, 210, 0.2);
}*/
table.tablesorter > thead > tr > .theader.sorter-false {
  background-image: none;
  cursor: default;
  padding-right: 8px;
}
table.tablesorter thead th a{
  color: #303743;
}
.table-responsive{
  margin: 0 0 20px;
  border: 1px solid $table-border-color;
}

.custom-table-footer{
  .total{
    margin: 0 0 15px;
  }
  .pagination{
    margin: 0 0 15px;
    float: right;
    @media (max-width: $screen-xs-max) {
      float: none;
    }
  }
}

.custom-table{
  .project-num{
    white-space: nowrap;
  }
  .project-ttl{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 300px;

    @media (max-width: 1399px) {
      max-width: 200px;
    }
    @media (max-width: 1199px) {
      max-width: none;
      white-space: normal;
    }
  }

  .badge{
    display: inline-block;
    font-weight: 400;
    padding: 2px 8px;
    margin: 0 0 0 10px;
    background: $brand-primary;
    border-radius: 3px;
    font-size: 12px;
    line-height: 20px;
    text-transform: uppercase;

    &.bg-danger{
      background: $brand-danger;
    }
  }
}

.admin{
  .custom-table {
    min-width: 700px;
  }

  .custom-table > tbody > tr > td{
    border-left: 1px solid $table-border-color;

    &:first-child{
      border-left: 0;
    }
  }
}
.mem-search-table{
  .no-wrap{
    display: block;
    white-space: nowrap;
  }
}

.client-table-sorter{
  .col-date{
    white-space: nowrap;
  }
}
.no-wrap{
  white-space: nowrap;
}

.table-border-column{
  .custom-table > tbody > tr > td{
    border-left: 1px solid $table-border-color;

    &:first-child{
      border-left: 0;
    }
  }
}

.custom-table > thead > tr > .th-sortable {
  padding: 0;
  a{
    display: block;
    padding: 8px 24px 8px 8px;
  }
}