.specialization-list{
    @include reset-list-styles;

    font-size: 13px;
    line-height: 20px;
    column-count: 3;
    column-gap: 0;
    border-top: 1px solid $border-color-grey;
    border-left: 1px solid $border-color-grey;

    @media (max-width: $screen-md-min) {
        column-count: 2;
    }

    @media (max-width: $screen-sm-min) {
        column-count: 1;
    }


    li{
        break-inside: avoid;
        border-bottom: 1px solid $border-color-grey;
        border-right: 1px solid $border-color-grey;
    }
}

.specialization{
    position: relative;
    color: $color-dark-grey;
    text-decoration: none;
    padding:0 10px;
    display: block;
    overflow: hidden;

    span{
        display: inline-block;
        max-width: 83%;
        vertical-align: middle;
    }

    &:hover,
    &:focus{
        background: $bg-color-grey;
        color: $color-dark-grey;
        text-decoration: none;
    }

    &.active{
        background: $brand-primary;
        color: #fff;
    }

    &:before{
        content: "\e91a";
        display: inline-block;
        vertical-align: middle;
        height: 32px;
        padding: 6px 0;
        width: 30px;
        margin-right: 5px;
        text-align: center;
        line-height: 20px;
        font-size: 18px;
        font-family: 'FontAwesome' !important;
        speak: none;
        font-style: normal;
        font-weight: normal;
        font-variant: normal;
        text-transform: none;
        -webkit-font-smoothing: antialiased;
    }
    .glyphicon{
        display: none;
    }
}

.specialization-art-and-culture:before{
    content: "\f19c";
}
.specialization-automotive:before{
    content: "\f1b9";
}
.specialization-building-and-construction:before{
    content: "\f1ad";
}
.specialization-certificates-and-diplomas:before{
    content: "\f0a3";
}
.specialization-education-and-e-learning:before{
    content: "\f19d";
}
.specialization-electronics:before{
    content: "\f108";
}
.specialization-energy-cleantech-and-environment:before{
    content: "\f06c";
}
.specialization-eu-and-public-sector:before{
    content: "\f0c0";
}
.specialization-finance-and-accounting:before{
    content: "\f0d6";
}
.specialization-forestry-and-agriculture:before{
    content: "\f1bb";
}
.specialization-furniture-and-interior-design:before{
    content: "\f040";
}
.specialization-hr-and-administration:before{
    content: "\f007";
}
.specialization-it-and-telecom:before{
    content: "\f121";
}
.specialization-legal-and-insurance:before{
    content: "\f0e3";
}
.specialization-life-sciences-and-healthcare:before{
    content: "\f0fa";
}
.specialization-manufacturing-and-processing:before{
    content: "\f085";
}
.specialization-military-and-defence:before{
    content: "\f0fb";
}
.specialization-patents:before{
    content: "\f044";
}
.specialization-pr-and-marketing:before{
    content: "\f028";
}
.specialization-retail-and-consumer-products:before{
    content: "\f07a";
}
.specialization-science:before{
    content: "\f0c3";
}
.specialization-social-media:before{
    content: "\f086";
}
.specialization-software:before{
    content: "\f109";
}
.specialization-textile-fashion-and-cosmetics:before{
    content: "\f0c4";
}
.specialization-tourism-and-travel:before{
    content: "\f072";
}
.specialization-transport-and-logistics:before{
    content: "\f239";
}
.specialization-intellectual-property:before{
    content: "\f0a0";
}
.specialization-public-sector:before{
    content: "\f0c0";
}
.specialization-eu:before {
    content: "\f153";
}