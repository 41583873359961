.issue-type{
    color: white;
    border-radius: 5px;
    padding:5px;
    width: 75px;
    text-align: center;
}

.comment-avatar{
    border-radius: 24px;
}