.project-preview{
    border-top:1px solid $border-color-light-grey;
    padding-top: 20px;

    &-item{
        border-bottom:1px solid $border-color-light-grey;
        margin: 0 0 20px;
    }
    &-content{
        padding: 0 0 20px;
        display: flex;

        @media (max-width: $screen-sm-min) {
            padding: 0 0 15px;
            display: block;
        }
    }

    &-holder{
        flex: 1 1 0;
        display: flex;
        flex-direction: column;

        @media (max-width: $screen-sm-min) {
            display: block;
        }
    }

    &-heading{
        // flex: 1 1 0;
        margin: 0 0 5px;

        h3{
            margin: 0 0 15px;
            font-size: 18px;
        }

        dl{
            margin: 0;

            @include clearfix;
        }

        dt{
            float: left;
            font-weight: 400;
            margin-right: 10px;
        }
        dd {
            margin-bottom: 10px;
            color: $text-light-color;
            overflow: hidden;
        }
    }

    &-footer{
        margin: 0 0 20px;
    }

    .progress{
        margin: 0;

        @media (max-width: $screen-sm-min) {
            margin: 0 0 15px;
        }
    }
    .progress-txt{
        font-size: 12px;
        line-height: 20px;
        font-weight: 400;
    }
    .delivery{
        margin-left: 20px;
        width: 200px;

        @media (max-width: $screen-sm-min) {
            margin: 0;
            width: 100%;
            min-height: 0;
        }
    }

    &-files {
        width: calc(100% - 220px);
        padding: 0 0;
        display: flex;

        @media (max-width: $screen-sm-min) {
            padding: 0 0 15px;
            display: block;
        }

        .project-files-preview-holder {
            -ms-flex: 1 1 0px;
            flex: 1 1 0;
            display: -ms-flexbox;
            display: flex;
            -ms-flex-direction: column;
            flex-direction: column;

            #ref-files-container {
                margin-bottom: 10px;
            }
        }
    }
}

.delivery{
    border: 1px solid $border-color-grey;
    border-radius: 3px;
    padding: 15px;
    background: #fff;
    text-align: center;
    align-items: center;
    display: flex;
    min-height: 180px;


    &-holder{
        flex: 1 1 0;
    }

    &-label{
        margin: 0 0 5px;
    }
    &-date,
    &-time{
        font-size: 18px;
        font-weight: 400;
        margin: 0 0 5px;
    }
    .fa{
        font-size: 30px;
        margin: 0 0 15px;
        display: inline-block;
    }

    &-status{
        &.delivered{
            color: $brand-success;
        }
        &.in-progress{
            color: $brand-warning;
        }
    }
}