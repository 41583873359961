.account-form{
    h2{
        margin: 0 0 25px;
    }
    .form-holder{
        max-width: 80%;

        @media (max-width: $screen-sm-min) {
            max-width: none;
            margin-bottom: 30px;
        }
    }
    .hint{
        margin-top: 10px;
    }
}
.account-info{
    padding: 15px 0;
    &-row{
        overflow: hidden;
        margin: 0 0 10px;
    }
    &-label{
        float: left;
        width: 200px;
        color: $text-color;
        @media (max-width: $screen-sm-min) {
            float: none;
            width: auto;
        }
    }
    &-val{
        overflow: visible;
        color: $text-light-color;
    }
}

#user-several-clients {
    h2 {
        margin-bottom: 10px;
    }

    ul {
        list-style: none;
        padding-left: 0;
    }
}