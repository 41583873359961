/* modal */

.modal-dialog-custom .modal-content{
    border: 0;
    box-shadow: none;
    background: #fff;
    border-radius: 0;
    text-align: center;
    padding: 15px;
}
.modal-dialog-custom .modal-body{
    padding: 15px;
    max-width: 600px;
    margin: 0 auto;
}
.modal-dialog-custom .modal-footer{
    border-top: 1px solid #eaebec;
    text-align: center;
    padding: 30px 15px 0;
    font-size: 20px;
}
.modal-dialog-custom .modal-footer a{
    font-weight: 600;
}
.modal-dialog-custom .modal-ico{
    margin: 0 auto 30px;
}
.modal-dialog-custom .close{
    position: absolute;
    top: 0;
    right: 0;
    width: 45px;
    height: 45px;
    margin: 0;
    color: #d7d8da;
    z-index: 3;
    font-size: 28px;
    line-height: 45px;
    text-align: center;
    opacity: 1;
}
.modal-dialog-custom .modal-btn{
    padding: 15px 0;
}
.modal-dialog-custom .modal-btn .btn{
    width: 100%;
    height: auto;
    white-space: normal;
    line-height: 1.5;
    min-height: 56px;
}
.modal-dialog-custom form{
    text-align: left;
}
@media (min-width: 768px){
    .modal-dialog.narrow{
        width: 500px;
    }
    .modal-dialog.wide{
        width: 600px;
    }
    .modal-dialog-custom .close{
        top: -45px;
        right: -45px;
    }
    .modal-dialog-custom .modal-content{
        padding: 30px;
    }
}
@media (min-width: 1120px){
    .modal-dialog.wide{
        width: 1024px;
    }
}