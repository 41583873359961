.lang-drop{
    padding: 0;
    margin: 0;
    border-radius: 0;
    border: 0;
    background: #fff;

    @media (max-width: $screen-sm-min) {
        width: 100%;
    }

    &-holder{
        border: 1px solid $border-color-grey;
    }

    &-header{
        padding: 20px;

        @media (max-width: $screen-sm-min) {
            padding: 15px;
        }
    }

    &-content{
        padding: 0 20px;

        @media (max-width: $screen-sm-min) {
            padding: 0 15px;
        }
    }

    li.not-in-agreement {

        a {
            color: #b1dcd6;
        }
    }
}

.recentlyLangs, .recentlyLangsMultiple{
    list-style: none;
    margin: 0 0 0 -5px;
    padding: 0;

    li{
        display: inline-block;
        vertical-align: top;
        margin: 0 10px 10px 0;
    }

    a {
        color: $brand-primary;
        display: inline-block;
        vertical-align: middle;
        font-size: 14px;
        padding: 5px;

        &:hover,
        &.active {
            color: #fff;;
            background: $brand-primary;
            text-decoration: none;
        }

        & > span{
            margin-left: 5px;
            font-size: 14px;
        }
    }
}

.langList{
    padding: 20px 0;
    column-count: 4;

    @media (max-width: $screen-md-min) {
        column-count: 3;
    }

    @media (max-width: $screen-sm-min) {
        padding: 15px 0;
        column-count: 2;
    }

    a{
        color: $brand-primary;
        display: block;
        font-size: 14px;
        padding: 5px 30px 5px 8px;
        margin: 0 0 3px;
        overflow: hidden;
        position: relative;

        &:hover,
        &.active {
            color: #fff;
            background: $brand-primary;
            text-decoration: none;
        }

        &> span{
            position: absolute;
            right: 0;
            top: 0;
            line-height: 26px;
            width: 26px;
            text-align: center;
        }
    }
}

.letter-box{
    break-inside: avoid;
    page-break-inside: avoid;
}

.letter-list{
    list-style: none;
    margin: 0 0 20px -8px;
    padding: 0;
}

/* target tags */
.target_languages_cont{
    .multiple-cont{
        padding: 10px 0 5px;
        display: block;
    }
    .tag{
        display: inline-block;
        vertical-align: top;
        color: #fff;
        background: #c1c3c6;
        padding: 5px 10px;
        font-size: 14px;
        line-height: 20px;
        font-weight: normal;
        margin:0 10px 10px 0;
        border-radius: 3px;
        height: auto;
    }
    .tag-remove {
        float: right;
        margin-left: 10px;
        font-size: 26px;
        line-height: 20px;
        cursor: pointer;
    }
}

.lang-list-block{
    border: 1px solid $border-color-grey;
    margin: 0 0 30px;
}
.lang-list-label{
    padding: 10px 15px;
    color: #303743;
    font-size: 16px;
    line-height: 20px;
}
.lang-list-block .lang-status{
    float: right;
    color: lighten($brand-success, 10%);

    &.offline{
        color: $text-light-color;
    }
}

.lang-list-block .lang-list img{
    margin-right: 15px;
}

.lang-list-block .lang-list{
    @include reset-list-styles;

    a{
        display: block;
        padding: 10px 15px;
        color: $color-dark-grey;

        &:after{
            content: "";
            display: block;
            clear: both;
        }

        &:hover{
            background: $bg-color-grey;
            color: $color-dark-grey;
            text-decoration: none;
        }

        &:active,
        &:focus{
            text-decoration: none;
        }
        &.active{
            background: $brand-primary;
            color: #fff;
            text-decoration: none;
        }
    }
}

.available-list-lang {
    margin-bottom: 15px;

    a:hover {
        cursor: pointer;
        color: $brand-primary;
        background-color: #fff;
    }
}

.bottom-note-text {
    margin-bottom: 0;
    padding-top: 10px;
    font-size: 12px;
}