.menu{
    @include reset-list-styles;
    font-size: 13px;
    line-height: 20px;
    font-weight: 400;
    margin: 0 -15px;

    a{
        display: block;
        padding: 10px 15px 10px 55px;
        color: #333;
        position: relative;
        transition: all .25s;

        > i,
        .disabled-feature > i{
            width: 24px;
            font-size: 20px;
            line-height: 24px;
            position: absolute;
            top: 50%;
            left: 15px;
            margin-top: -12px;
            text-align: center;
            color: #888;
        }

        .disabled-feature > i{
            color: $color-light-grey;
        }

        // > i.fa-cart-arrow-down{
        //     font-size: 18px;
        // }

        &:hover,
        &:focus{
            color: $text-color;
            text-decoration: none;
            background: #dedede;

            > i{
                color: $text-color;
            }
        }
    }

    li.active > a{
        color: $brand-primary;
        text-decoration: none;
        background: #fff;

        > i{
            color: $brand-primary;
        }
    }

    li.disabled > a{
        color: $color-light-grey;
        text-decoration: none;
        background: #f3f3f3;

        > i{
            color: $color-light-grey;
        }
    }
    span.disabled-feature {
        padding-right: 15px;
    }

    .badge{
        display: inline-block;
        font-weight: 400;
        padding: 2px 8px;
        margin: -2px -15px -2px 10px;
        background: $brand-primary;
        border-radius: 3px;
        font-size: 12px;
        line-height: 20px;
        text-transform: uppercase;

        .plus{
            font-size: 20px;
            line-height: 1;
            margin-right: 5px;
            float: left;
        }

        &.bg-danger{
            background: $brand-danger;
        }
    }
}