#project-quote-search {
   .btn {
     white-space: nowrap;
   }

  .order-custom-filter-container {
    margin-bottom: 15px;

    .order-custom-filter {
      .client-name, .from-date, .to-date {
        margin-bottom: 15px;
      }

      .from-date {
        padding-right: 0;
        padding-left: 0;
      }
    }
  }
}

.pagination-page-count {
  line-height: 4;
}

.datepicker-calendar-left {
  padding-left: 8px;
}

.datepicker-calendar-right {
  padding-right: 8px;
}

.custom-filter-btn {
  width: 100%;
}

.custom-filter {
  display: none;
  margin-bottom: 15px;
}

.charts-content {
  .currency-type-list {
    .currency-type-item {
      span {
        display: inline-block;
        font-size: 15px;
      }
      span.value {
        font-weight: 500;
      }
    }
  }
}

#custom-filter-amount-number, #custom-order-count-filter, #custom-filter-user-count {
  .form-control {
    margin-bottom: 15px;
  }
}

.visual-statistic {
  margin-bottom: 55px;
}

.card-list-filters {
  margin: 15px 0 10px;
}

.amount-custom-filter {
  margin-bottom: 15px;

  .from-date, .to-date, .client-name {
    margin-bottom: 15px;
  }

  .from-date {
    padding-right: 0;
    padding-left: 0;
  }
}