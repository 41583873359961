.chat{
  .chat-wrap{
    max-height: 400px;
    overflow-y: auto;
  }
  .chat-row{
    overflow: hidden;
    text-align: left;

    &.from{
      text-align: right;

      .msg{
        background: #6cb5e1;
      }
    }
  }

  .msg{
    padding:10px;
    border-radius: 5px;
    background: #ddd;
    text-align: left;
    max-width: 75%;
    display: inline-block;
  }

  .data{
    font-size: 12px;
  }

  .name{
    max-width: 75%;
    display: inline-block;
  }
}