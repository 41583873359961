#termDetails {
  .drag-drop-container {
    display: none;
  }

  .image-preview-container {
    display: none;

    .action-button-container {
      position: relative;
      border: 1px solid #eee;
      border-radius: 3px;

      .image-preview-actions{
        position: absolute;
        z-index: 10;
        right: 10px;
        top: 10px;
      }
      .image-preview {
        img {
          width: 100%;
        }
      }
    }
  }
}

.mem-search {
  .btn-file {
    position: relative;
    overflow: hidden;
  }
  .btn-file input[type=file] {
    position: absolute;
    top: 0;
    right: 0;
    min-width: 100%;
    min-height: 100%;
    font-size: 999px;
    text-align: right;
    filter: alpha(opacity=0);
    opacity: 0;
    background: red;
    cursor: inherit;
    display: block;
  }
  input[readonly] {
    background-color: white !important;
    cursor: text !important;
  }

  .term-table-spinner-container {
    display: none;
    .spinner {
      margin-top: 50px;
    }
  }
}

.termDetailModal{
  .modal-title{
    font-size: 20px;
  }
  .text-light{
    color: $text-light-color;
  }
  .term-title {
    font-size: 20px;
    font-weight: 400;
    display: inline;
    vertical-align: middle;
  }
  .term-info{
    margin-bottom: 10px;
  }
  .term-hint{
    padding-top: 5px;
  }
  .term-control{
    display: inline-block;
    vertical-align: middle;
    padding-top: 5px;

    a{
      margin-left: 10px;
    }
  }
  .no-term .term-title{
    color: $text-light-color;
  }
  .dont-use .term-title{
    color: $brand-danger;
  }
  .term-form-row + .term-form-row{
    margin-top: 10px;
  }
  .term-input{
    display: inline-block;
    vertical-align: middle;
    max-width: 320px;
  }

  .termBase-comment {
    label {
      font-size: 15px;
    }
    .termbase-comment {
      resize: none;
      height: 70px;
      min-height: 70px;
    }
  }
  .term-comment {
    display: none;
    padding-top: 5px;
    font-size: 16px;
    font-weight: 400;

    .termBase-comment-value {
      font-weight: 300;
    }
  }
}

.sortable-list{
  ul{
    list-style: none;
    margin: 0;
    padding: 0;

    li{
      display: inline-block;
      vertical-align: top;
      margin: 0 5px 5px 0;
    }
  }

  .lang-item{
    display: inline-block;
    color: #fff;
    background: #c1c3c6;
    padding: 5px 10px;
    font-size: 14px;
    line-height: 20px;
    font-weight: normal;
    border-radius: 3px;
    cursor: move;
  }
}

.term-search{
  .btn-link:first-child{
    padding-left: 0;
  }
  .custom-row{
    margin: 0 -10px;

    .form-group{
      padding-right: 10px;
      padding-left: 10px;
    }

    .form-group.has-error{
      small.help-block{
        font-size: 1em;
      }
    }
  }
  p.help-block{
    color: #acb4c1;
  }
  small.help-block{
    font-size: 1em;
  }
  .form-group.has-error{
    p.help-block{
      display: none;
    }
  }
}

.table-scroll {
  position: relative;
  width:100%;
  z-index: 1;
  margin: 0 auto 20px;
  overflow: scroll;
  max-height: 600px;
  border: 1px solid #d6d8da;
  background: #f7f7f7;

  table {
    width: 100%;
    //min-width: 1140px;
    margin: auto;
    border-collapse: separate;
    border-spacing: 0;
  }

  th,
  td {
    padding: 8px;
    border: 0;
    border-left: 1px solid #d6d8da;
    //background: #fff;
    vertical-align: top;
  }

  th:first-child,
  td:first-child {
    border-left: 0;
  }

  td {
    vertical-align: middle;
  }

  thead th {
    background: #dedede;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    color: #303743;
    font-weight: 400;
    z-index: 10;
  }

  tfoot,
  tfoot th,
  tfoot td {
    position: -webkit-sticky;
    position: sticky;
    bottom: 0;
    background: #dedede;
    color: #fff;
    z-index:4;
    color: #303743;
    font-weight: 400;
  }

  th.col-sticky {
    position: -webkit-sticky;
    position: sticky;
    left: 0;
    z-index: 2;
    vertical-align: middle;
    border-left: 0;
    border-right: 1px solid #d6d8da;
    text-align: center;
  }

  tbody th{
    background: #fff;
  }

  thead th:first-child,
  tfoot th:first-child {
    z-index: 5;
  }

  .actions{
    white-space: nowrap;
    text-align: center;
  }

  .col-sticky{
    width: 60px;
    border-bottom: 1px solid #d6d8da;
  }

  .col-sticky + th,
  .col-sticky + td{
    border-left: 0;
  }

  table.table-striped {
    > tbody > tr:nth-of-type(odd) {
      background-color: #fff;
    }

    > tbody > tr:nth-of-type(even) {
      background-color: #f7f7f7;
    }
  }

  table.table-hover {
    > tbody > tr:hover {
      background-color: #cde7f6;
    }
  }
}