.approve-box{
    width: 80%;
    max-width: 900px;

    @media (max-width: $screen-md-min) {
        width: 90%;
    }

    @media (max-width: $screen-sm-min) {
        width: auto;
    }

    .scroll-panel{
        text-align: center;
        background: $brand-primary;
        font-size: 18px;
        padding: 15px;
        line-height: 1.2;
        color: #fff;
    }
    .modal-content{
        border: 0;
        box-shadow: none;
        background: #fff;
        border-radius: 0;
        padding: 30px 15px;
    }
    .margin-btm{
        margin-bottom: 30px;
    }
    .add-logo{
        margin: 0 0 15px;
        img{
            max-width: 160px;
            height: auto;
        }
    }
    .list{
        @include reset-list-styles;
        margin-bottom: 10px;
        margin-right: -5px;

        li{
            margin: 0 0 10px;
        }
    }
    .list-inline li{
        border-left: 1px solid $border-color-grey;
        margin: 0;

        &:first-child{
            border-left: 0;
        }
    }

    .lbl,
    .is-blue{
        color: #fc8527;
    }

    .quote-title{
        color: #fc8527;
        font-size: 30px;
        line-height: 1.2;
        margin: 0 0 10px;
        display: block;
    }
    .table-holder{
        margin: 0 -5px 30px;
    }
    table{
        width: 100%;
        background: none;
        table-layout: fixed;

        td, th{
            padding: 5px;
        }

        th{
            font-weight: normal;
        }

        .col-auto{
            width: 50%;
        }
    }
    .footer-btns .btn{
        min-height: 52px;
        min-width: 120px;
        margin-bottom: 15px;

        @media (max-width: $screen-sm-min) {
            width: 100%;
        }
    }

    .text-danger{
        color: $brand-danger;
    }
}