/* aside-box */
.aside-box{
    padding: 20px;
    margin: 0 0 20px;
    background: #fff;
    border: 1px solid $border-color-grey;
    border-radius: 3px;

    &-title{
        color: $gray-base;
        font-size: 20px;
        font-weight: 400;
        margin: 0 0 15px;
    }
}
.quotes-approve{
    padding: 20px 0;
    border-top: 1px solid $border-color-light-grey;

    &:first-child{
        padding-top: 0;
        border-top: 0;
    }

    &:last-child {
        padding-bottom: 0;
    }

    &-heading{
        height: 120px;

        h3{
            margin: 0 0 10px;
            font-size: 18px;
            line-height: 25px;
            max-height: 50px;
            overflow: hidden;
        }
    }

    &-data{
        overflow: hidden;
        padding: 0 0 20px;

        p{
            font-weight: 600;
            margin: 0 0 5px;
        }
        a{
            font-weight: normal;
        }
        .icon{
            float: left;
            width: 50px;
            font-size: 30px;
            margin: 0 0 10px;
        }
        .holder{
            overflow: hidden;
        }
    }
}

.aside-box.processing{
    text-align: center;
    font-size: 20px;
    line-height: 1.2;
}
.aside-box.processing i{
    font-size: 30px;
    margin: 0 0 15px;
    display: inline-block;
}

.aside-box.processing {
    .approval-quote {
        padding-top: 10px;

        .spinner {
            margin: 40px auto;
        }
    }
}

.aside-box.processing .holder{
    min-height: 180px;
    width: 100%;
    display: table;

    @media (max-width: $screen-sm-min) {
        min-height: 0;
    }
}
.aside-box.processing .cell{
    display: table-cell;
    vertical-align: middle;
}
.quote-not-ready{
    border: 1px solid #d6d8da;
    padding: 5px 15px;
    height: 60px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}
.quote-not-ready .icon {
    font-size: 36px;
    margin: 0 15px 0 0;
    display: inline-block;
    vertical-align: middle;
}
.quote-ready p,
.quote-not-ready p{
    display: inline-block;
    vertical-align: middle;
    margin: 0;
}
.quote-ready a{
    display: flex;
    color: #fff;
    padding: 5px 15px;
    height: 60px;
    text-align: center;
    background: #399300;
    border: 1px solid #399300;
    align-items: center;
    justify-content: center;
}
.quote-ready a:hover{
    background: #61a933;
    text-decoration: none;
}

.adv-box{
    background: #fff;

    &-header{
        background: #3496d2;
        padding: 13px 15px;
        text-align: center;
        color: #fff;
        font-size: 20px;
        font-weight: 600
    }
    &-footer{
        border: 1px solid $border-color-grey;
        padding: 15px;
        text-align: center;
    }
    &-content{
        padding: 15px;
        border: solid $border-color-grey;
        border-width: 0 1px;

        &-holder{
            text-align: center;
            color: $text-light-color;
            max-width: 70%;
            margin: 0 auto;
        }
        h3{
            color: $text-color;
            font-size: 24px;
            font-weight: 600;
            margin: 0 0 15px;
        }
        .fa{
            display: inline-block;
            margin: 0 0 15px;
            color: $text-color;
            font-size: 50px;
        }
    }
}

.amestoGo-link{
    display: block;
    padding: 20px;
    text-align: center;
    border: 1px solid $border-color-grey;
    color: $text-color;
    margin: 25px 0;

    &:hover,
    &:focus{
        text-decoration: none;
        color: inherit;
    }
    .fa{
        display: inline-block;
        margin: 0 0 15px;
        color: $brand-danger;
        font-size: 50px; 
    }
    h3{
        color: $brand-danger;
        font-size: 28px;
        font-weight: 600;
        margin: 0 0 15px;
    }
    h4{
        font-weight: 400;
        font-size: 20px;
        margin: 0 0 15px;
    }
    p{
        margin: 0 0 15px;
    }
    p span{
        color: $link-color;
    }
    ul{
        text-align: left;
        padding: 0 0 0 30px;
        margin: 0 0 15px;
    }
}

.order-summary{
    .saved{
        font-size: 20px;
        font-weight: 400;
        margin: 0 0 10px;
    }
    .total-price{
        overflow: hidden;
        border: solid $border-color-light-grey;
        border-width: 1px 0;
        padding: 15px 0;
        font-size: 16px;
        margin-bottom: 20px;

        &-value{
            text-align: right;
        }
    }

    &-btm{
        .btn + .btn{
            margin-top: 15px;
        }
    }
}