input[type=text],
input[type=search],
input[type=email]{
    -webkit-appearance: none;
}

.form-control{
    box-shadow: none;
    text-align: left;
}
.form-control:focus{
    box-shadow: none;
    border-color: $border-color-grey;
}
.form-group{
    margin-bottom: 20px;
}
.form-group .input-group-addon {
    background: none;
    padding: 5px 10px;
}
label, .label {
    margin:0 0 5px;
    color: $text-color;
    font-size: 13px;
    line-height: 20px;
    font-weight: 400;
    display: inline-block;
    padding: 0;
    border-radius: 0;
    text-align: left;
    white-space: normal;
}
textarea.form-control,
.textarea.form-control {
    min-height: 100px;
    max-width: 100%;
    min-width: 100%;
    height: 100px;
}
.textarea.form-control{
    overflow: auto;
}
.textarea.form-control .mark{
    background: #f2dede;
}
.form-control[disabled],
.form-control[readonly],
fieldset[disabled] .form-control {
    background-color: #f5f5f5;
    filter: grayscale(1);
}
.info-label{
    margin: 10px 0 0;
    color: $color-light-grey;
}
[type="checkbox"].custom:not(:checked),
[type="checkbox"].custom:checked,
[type="radio"].custom:not(:checked),
[type="radio"].custom:checked {
    position: absolute;
    left: -9999px;
}
[type="checkbox"].custom:not(:checked) + label,
[type="checkbox"].custom:checked + label,
[type="radio"].custom:not(:checked) + label,
[type="radio"].custom:checked + label {
    position: relative;
    cursor: pointer;
    margin: 0;
    padding-left: 30px;
    min-height: 18px;
}
[type="checkbox"].custom:not(:checked) + label:before,
[type="checkbox"].custom:checked + label:before,
[type="radio"].custom:not(:checked) + label:before,
[type="radio"].custom:checked + label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 20px;
    height: 20px;
    border: 1px solid #d6d8da;
    background: #fff;
    border-radius: 3px;
    box-shadow: none;
}
[type="radio"].custom:not(:checked) + label:before,
[type="radio"].custom:checked + label:before{
    border-radius: 50%;
}
[type="checkbox"].custom:not(:checked) + label:after,
[type="checkbox"].custom:checked + label:after,
[type="radio"].custom:not(:checked) + label:after,
[type="radio"].custom:checked + label:after {
    content: '\f00c';
    position: absolute;
    top: 0;
    left: 0;
    color: $brand-primary;
    width: 20px;
    text-align: center;
    font: 15px/20px "FontAwesome";
    transition: all .2s;
}
[type="checkbox"].custom:not(:checked) + label:after,
[type="radio"].custom:not(:checked) + label:after {
    opacity: 0;
    transform: scale(0);
}
[type="checkbox"].custom.big:not(:checked) + label,
[type="checkbox"].custom.big:checked + label,
[type="radio"].custom.big:not(:checked) + label,
[type="radio"].custom.big:checked + label{
    padding: 5px 0 0 50px;
    min-height: 30px;
}
[type="checkbox"].custom.big:not(:checked) + label:before,
[type="checkbox"].custom.big:checked + label:before,
[type="radio"].custom.big:not(:checked) + label:before,
[type="radio"].custom.big:checked + label:before {
    width: 30px;
    height: 30px;
}
[type="checkbox"].custom.big:not(:checked) + label:after,
[type="checkbox"].custom.big:checked + label:after,
[type="radio"].custom.big:not(:checked) + label:after,
[type="radio"].custom.big:checked + label:after {
    width: 30px;
    font:20px/30px "FontAwesome";
}
.check-item label{
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
}

.settings-table {
    .unavailable label::before {
        background-color: #d6d8da !important;
        border-color: #acb4c1 !important;
    }
}


.multiselect{
    width: 100%;
    position: relative;

    &-btn{
        text-align: left;
        position: relative;
        padding-right: 30px;

        .fa{
            right: 0;
            top: 0;
            width: 32px;
            height: 32px;
            line-height: 30px;
            position: absolute;
            text-align: center;
            color: $text-light-color;
        }

        .multiselect-selected-text{
            display: block;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }

    &-container{
        width: 100%;
        padding: 0;
        margin: 0;
        border-radius: 0;
        background: #fff;
        border: 1px solid $border-color-grey;
        max-height: 400px;
        overflow: auto;
        min-width: 240px;
    }

    &-holder{
        padding: 15px;
    }

    &-list{
        @include reset-list-styles;
        font-size: 16px;
        line-height: 20px;

        a{
            color: $text-color;
            display: block;
            padding: 10px 15px;

            &:hover{
                color: #fff;
                background: $brand-primary;
                text-decoration: none;
            }
        }
    }
}


.files-upload-box {
    background: url(/img/doc.png) no-repeat center 50px #fbfbfb;
    height: 190px;
    border: 1px dashed #d6d8da;
    border-radius: 3px;
    padding: 120px 20px 20px;
    text-align: center;
    margin: 0 0 10px;
    color: #b9b9b9;
    font-size: 16px;
    line-height: 20px;
    font-weight: normal;
}
.files-upload-box .upload-bnt label{
    font-weight: normal;
    color: $brand-primary;
    cursor: pointer;
    font-size: 16px;
}
.uploaded-files{
    padding: 15px 0;
}
.files-list{
    @include reset-list-styles();

    li{
        margin: 0 0 15px;
        position: relative;
        padding: 0 30px;
        overflow: hidden;

        &:before{
            content: "\f15b";
            position: absolute;
            left: 0;
            top: 0;
            color: $border-color-grey;
            display: inline-block;
            font-family: FontAwesome;
        }
    }

    &-link{
        position: absolute;
        top: 0;
        right: 0;
        width: 20px;
        height: 20px;
        cursor: pointer;
        color: $border-color-grey;
    }
}


.form-dropdown-label{

    .label{
        margin: 0;
        color: #363d48;
        text-decoration: none;
        position: relative;
        padding-right: 30px;

        i{
            font-size: 24px;
            width: 24px;
            height: 24px;
            text-align: center;
            color: $brand-primary-2;
            right: 0;
            top: 50%;
            margin-top: -12px;
            position: absolute;
        }

        &:hover,
        &:focus,
        &:active{
            color: #363d48;
            text-decoration: none;
        }
    }

    &-content{
        padding-top: 10px;
    }

    // &.collapsed i:before{
    //     content: "\f105";
    // }
}

.table-search{
    float: right;
    position: relative;
    padding-right: 32px;
    min-width: 250px;

    @media (max-width: $screen-sm-min) {
        float: none;
    }

    .btn{
        position: absolute;
        top: 0;
        right: 0;
        cursor: default;
        padding: 5px;
        width: 32px;
    }
}

/* switcher */
.switcher{
    [type="checkbox"]:not(:checked),
    [type="checkbox"]:checked,
    [type="radio"]:not(:checked),
    [type="radio"]:checked {
        position: absolute;
        left: -9999px;
    }

    .switcher-val{
        color: $text-light-color;
        text-transform: uppercase;
        font-weight: normal;
        line-height: 30px;
        display: inline-block;
        vertical-align: middle;
    }
    label,
    [type="checkbox"]:not(:checked) + label,
    [type="checkbox"]:checked + label,
    [type="radio"]:not(:checked) + label,
    [type="radio"]:checked + label{
        display: inline-block;
        margin: 0;
        padding: 0;
        min-height: 30px;
    }
    label:before,
    label:after{
        display: none;
    }
    .switcher-item{
        display: inline-block;
        vertical-align: middle;
        width: 50px;
        height: 30px;
        position: relative;
        margin: 0 15px;
    }
    input[type="checkbox"] + label .switcher-item:before,
    input[type="radio"] + label .switcher-item:before {
        content: "";
        position: absolute;
        display: block;
        left: 0;
        top: 0;
        width: 50px; /* x*5 */
        height: 30px; /* x*3 */
        border-radius: 16px; /* x*2 */
        background: $input-bg-disabled;
        border: 1px solid $border-color-grey;
        -webkit-transition: all 0.3s;
        transition: all 0.3s;
    }
    input[type="checkbox"] + label .switcher-item:after,
    input[type="radio"] + label .switcher-item:after {
        content: "";
        position: absolute;
        display: block;
        left: 0;
        top: 0;
        width: 30px; /* x*3 */
        height: 30px; /* x*3 */
        border-radius: 16px; /* x*2 */
        background: #fff;
        border: 1px solid $border-color-grey;
        -webkit-transition: all 0.3s;
        transition: all 0.3s;
        opacity: 1;
        transform: none;
    }
    input[type="checkbox"] + label .switcher-item:hover:after,
    input[type="radio"] + label .switcher-item:hover:after {
        box-shadow: 0 0 5px rgba(0,0,0,0.3);
    }
    input[type="checkbox"]:checked + label .switcher-item:after,
    input[type="radio"]:checked + label .switcher-item:after {
        margin-left: 20px;
    }
    input[type="checkbox"]:checked + label .switcher-item:before,
    input[type="radio"]:checked + label .switcher-item:before{
        background: $brand-primary;
    }
    input[type="checkbox"]:checked + label .checked,
    input[type="radio"]:checked + label .checked{
        color: $brand-primary;
    }
    input[type="checkbox"]:checked + label .switcher-val.checked{
        color: $brand-primary;
    }
}

.intl-tel-input {
    width: 100%;

    .flag-container {
        width: 100%;
        height: 32px;
    }
    .country-list {
        width: 100%;
        overflow-x: hidden;
    }
}

.controls{
     .entry{
        margin-bottom: 10px;
    }

    .btn{
        padding: 5px;
        width: 32px;
    }
}

.form-btns{
    .btn{
        margin-bottom: 15px;
    }
}

.form-control.large,
.btn-primary.large{
    height: 50px;
    padding: 10px 15px;
    font-size: 20px;
    text-align: center;
}

.submitLink {
    background-color: transparent;
    text-decoration: none;
    border: none;
    color: $brand-primary;
    cursor: pointer;
}
.submitLink:focus, .submitLink:hover {
    color: #65b3e3;
    text-decoration: none;
}

.form-group.required label:after {
    content:"*";
    color:red;
}

.log-date-filter {

    .form-group {

        display: inline-block;
        width: calc( 50% - 12px );
    }

    .form-group:first-child {
        margin-right: 10px;
    }

    .form-group:last-child {
        margin-left: 10px;
    }
}