.aside-contact{
    margin: 0 0 15px;
    padding-bottom: 5px;
    border-bottom: 1px solid $border-color-grey;

    &:last-child{
        margin: 0;
        padding-bottom: 0;
        border-bottom: 0;
    }

    .avatar{
        float: left;
        max-width: 100px;
        margin: 0 15px 0 0;
        border: 1px solid $border-color-grey;

        img{
            width: 100%;
            height: auto;
            display: block;
        }
    }

    .info{
        overflow: hidden;
        color: $text-light-color;

        .dt,
        .name{
            color: $text-color;
            margin: 0;
        }

        .name{
            font-size: 18px;
            line-height: 22px;
            font-weight: 400;
        }

        .link{
            color: $text-light-color;
        }
    }
}

.contact-panel{
    background: #f3f3f3;

    .container-fluid{
        max-width: 1170px;
    }

    .flex-row{
        display: flex;
        align-items: center;
    }

    .aside-contact{
        position: relative;
        padding: 20px 0 20px 50%;
    }

    .avatar{
        margin: 0;
        border: 0;
        max-width: 50%;
        position: absolute;
        bottom: 0;
        left: 0;
    }
}


@media (max-width:991px) {
    .contact-panel > .container > .row{
        display: block;
    }
}
@media (max-width:767px) {
    .contact-panel .aside-contact{
        position: relative;
        padding-left: 150px;
    }
    .contact-panel .aside-contact .avatar{
        max-width: 150px;
    }
}