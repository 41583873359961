.chosen-container{
    font-size: 14px;
    width: 100% !important;
}
.chosen-container-single{
    .chosen-single{
        display: block;
        width: 100%;
        height: 32px;
        padding: 5px 15px;
        font-size: 13px;
        line-height: 20px;
        color: #363d48;
        background-color: #fff;
        background-image: none;
        border: 1px solid #d6d8da;
        border-radius: 3px;
        box-shadow: none;
        transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
    }
    .chosen-single span {
        margin-right: 25px;
    }
    .chosen-single div {
        right: 0;
        top: 0;
        width: 32px;
        height: 32px;
        position: absolute;
        text-align: center;

        &:after{
            right: 0;
            top: 0;
            width: 32px;
            height: 32px;
            font-size: 20px;
            line-height: 30px;
            position: absolute;
            text-align: center;
            color: #bfc0c1;
            display: inline-block;
            font-family: FontAwesome;
            font-size: inherit;
            text-rendering: auto;
            -webkit-font-smoothing: antialiased;
            content: "\f107";
        }

        b{
            display: none;
        }
    }
}
.chosen-container-active.chosen-with-drop .chosen-single {
    border: 1px solid #d6d8da;
    border-radius: 0;
    background-image:none;
    box-shadow: none;
}
.chosen-container .chosen-drop {
    background: #fff;
    border: 1px solid #eeeff0;
    box-shadow: 0 5px 8px #bbb;
    padding: 5px;
    margin: 0;
    border-radius: 0;
    opacity: 0;
}
.chosen-container.chosen-with-drop .chosen-drop{
    opacity: 1;
}
.chosen-container-single .chosen-search {
    padding: 0;
}
.chosen-container-single .chosen-search input[type="text"]{
    margin: 0;
    display: block;
    width: 100%;
    height: 32px;
    padding: 5px 15px;
    font-size: 13px;
    line-height: 20px;
    color: #363d48;
    background-color: #fff;
    background-image: none;
    border: 1px solid #d6d8da;
    border-radius: 0;
    box-shadow: none;
}
.chosen-container .chosen-results {
    list-style: none;
    margin: 0;
    padding: 0;

    li.active-result {

        &.not-in-agreement {

            color: #b1dcd6;
        }
    }
}
.chosen-container .chosen-results li,
.chosen-container .chosen-results li.highlighted {
    font-size: 13px;
    line-height: 20px;
    color: #303743;
    display: block;
    padding: 5px 15px;
    cursor: pointer;
    background: none;
}
.chosen-container .chosen-results li.result-selected {
    color: #fff;
    background: $brand-primary;
    text-decoration: none;
}
.chosen-container .chosen-results li:hover {
    background: #dedede;
    color: #303743;
    text-decoration: none;
}
.chosen-container .chosen-results li em {
    font-style: normal;
    text-decoration: none;
    background: #d0e8f7;
}

.chosen-container-multi .chosen-choices {
    margin: 0;
    width: 100%;
    height: auto;
    cursor: text;
    background: none;
    border: 0;
    min-height: 0;
    box-shadow: none;
    border-radius: 0;
    padding: 0;
}
.chosen-container-multi .chosen-choices li.search-field{
    margin: 0;
    width: 100%;
    height: auto;
    cursor: text;
    background: #fff;
    border: 1px solid #d6d8da;
    min-height: 32px;
    box-shadow: none;
    border-radius: 3px;
    font-size: 13px;
    line-height: 20px;
    padding: 5px 15px;

    &:before{
        right: 0;
        top: 0;
        width: 32px;
        height: 32px;
        font-size: 20px;
        line-height: 30px;
        position: absolute;
        text-align: center;
        color: #bfc0c1;
        display: inline-block;
        font-family: FontAwesome;
        font-size: inherit;
        text-rendering: auto;
        -webkit-font-smoothing: antialiased;
        content: "\f107";
        cursor: pointer;
    }

    input[type="text"]{
        width: 100% !important;
        margin: 0;
        height: auto;
        font-size: 13px;
        line-height: 20px;
        font-family: $font-family-sans-serif;
        color: #303743;
    }
}
.chosen-container-multi .chosen-choices li.search-choice {
    position: relative;
    margin: 3px 5px 3px 0;
    padding: 3px 28px 3px 5px;
    max-width: 100%;
    background: #c1c3c6;
    color: #fff;
    line-height: 20px;
    cursor: default;
    border: 0;
    border-radius: 0;
    box-shadow: none;
}
.chosen-container-multi .chosen-choices li.search-choice .search-choice-close {
    position: absolute;
    top: 3px;
    right: 3px;
    display: block;
    width: 20px;
    height: 20px;
    font: normal normal normal 13px/1 FontAwesome;
    color: #fff;
    text-align: center;
    background: none !important;

    &:hover{
        background: none !important;
    }

    &:before {
        content: "\f00d";
        line-height: 20px;
    }
}
.select-custom-multiple {
    .chosen-container-multi .chosen-choices {
        padding: 38px 0 0;
    }
    .chosen-container-multi .chosen-choices li.search-field{
        position: absolute;
        top: 0;
        left: 0;
        overflow: hidden;
    }
    .chosen-container .chosen-drop{
        top: 32px;
    }
    p{
        color: $text-light-color;
    }
    .chosen-results li.result-selected{
        background: $brand-primary;
        padding-left: 40px;
        position: relative;

        &:before{
            content: '\f00c';
            position: absolute;
            top: 5px;
            left: 10px;
            color: #fff;
            width: 18px;
            text-align: center;
            font: 15px/18px "FontAwesome";
        }

        &:hover{
            color: #fff;
        }
    }
}

.quote-chosen-lang, .mem-search {
    .highlighted-active {
        color: #fff !important;
        background: #c1c3c6 !important;
    }
}