@font-face {
    font-family:'ProximaNova';
    src:url('../fonts/ProximaNovaRegular.eot');
    src:url('../fonts/ProximaNovaRegular.eot?#iefix') format('embedded-opentype'),
         url('../fonts/ProximaNovaRegular.woff') format('woff'),
         url('../fonts/ProximaNovaRegular.ttf') format('truetype'),
         url('../fonts/ProximaNovaRegular.svg#ProximaNova') format('svg');
    font-weight:normal;
    font-style:normal;
}

@font-face {
    font-family:'ProximaNova';
    src:url('../fonts/ProximaNovaLight.eot');
    src:url('../fonts/ProximaNovaLight.eot?#iefix') format('embedded-opentype'),
         url('../fonts/ProximaNovaLight.woff') format('woff'),
         url('../fonts/ProximaNovaLight.ttf') format('truetype'),
         url('../fonts/ProximaNovaLight.svg#ProximaNova') format('svg');
    font-weight:300;
    font-style:normal;
}

@font-face {
    font-family:'ProximaNova';
    src:url('../fonts/ProximaNovaBold.eot');
    src:url('../fonts/ProximaNovaBold.eot?#iefix') format('embedded-opentype'),
         url('../fonts/ProximaNovaBold.woff') format('woff'),
         url('../fonts/ProximaNovaBold.ttf') format('truetype'),
         url('../fonts/ProximaNovaBold.svg#ProximaNova') format('svg');
    font-weight:700;
    font-style:normal;
}

@font-face {
    font-family:'ProximaNova';
    src:url('../fonts/ProximaNovaSemiBold.eot');
    src:url('../fonts/ProximaNovaSemiBold.eot?#iefix') format('embedded-opentype'),
         url('../fonts/ProximaNovaSemiBold.woff') format('woff'),
         url('../fonts/ProximaNovaSemiBold.ttf') format('truetype'),
         url('../fonts/ProximaNovaSemiBold.svg#ProximaNova') format('svg');
    font-weight:600;
    font-style:normal;
}