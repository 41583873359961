.faq-list{
    border-top: 1px solid $border-color-grey;
    margin: 0 0 30px;

    .panel{
        margin: 0;
        border: 0;
        border-bottom: 1px solid $border-color-grey;
        box-shadow: none;
    }

    .panel-body{
        padding: 15px 0;

            p{
            margin: 0 0 15px;
        }
    }

    .panel-title{
        font-size: 18px;
        line-height: 22px;
        font-weight: 400;
        margin: 0;
        position: relative;

        a{
            color: $color-dark-grey;
            display: block;
            overflow: hidden;
            padding: 15px 40px 15px 0;
            white-space: nowrap;
            text-overflow: ellipsis;

            &:hover{
                text-decoration: none;
                color: $brand-primary;
            }

            &:focus,
            &:active{
                text-decoration: none;
                outline: none;
            }
        }

        i{
            position: absolute;
            width: 24px;
            height: 24px;
            text-align: center;
            color: $text-light-color;
            right: 0;
            top: 50%;
            margin-top: -12px;
            font-size: 24px;
        }

        .collapsed i:before{
            content: "\f105";
        }
    }
}