.spinner {
    animation: spin 1.5s linear infinite;
    position: relative;
    width: 120px;
    height: 120px;
    margin: 50px auto;
    // margin-top:145px;
    border: 2px solid $brand-primary-2;
    border-radius: 50%;

    /*
    display:none;
    .show{
        display: block;
    }
    */
    &:after {
        content:"";
        width: 85%;
        height: 85%;
        background: -webkit-linear-gradient(45deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 40%, rgba(255,255,255,0.7) 60%, rgba(255,255,255,0) 100%);
        background: linear-gradient(45deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 40%, rgba(255,255,255,0.7) 60%, rgba(255,255,255,0) 100%);
        position: absolute;
        top:0;
        left: 0;
        transform: translate(-8%,-8%);
    }

    &.no-margin {
        margin-top: 0px !important;
    }
}

.spinner-holder{
    display:none;
}
.spinner-text{
    text-align: center;
}
.mini{
    .spinner{
        margin: 10px 15px 10px 0;
        width:40px;
        height: 40px;
        display: inline-block;
        vertical-align: middle;
    }

    .spinner-text{
        display: inline-block;
        vertical-align: middle;
    }
    p{
        margin: 15px 0;
    }
}

@keyframes spin {
    0%   { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}