.jobs-review {

  .review-content {
    border: 1px solid #eee;
    border-top: 2px solid $brand-primary;
    border-radius: 2px 2px 0 0;
    padding: 10px;
  }

  .base-info {
    h2 {
      display: inline-block;
      margin-bottom: 0;
    }
    p {
      font-size: 14px;
    }
  }

  .file-review {
    .job-name {
      font-size: 15px;
      line-height: 2;
    }

    .fa-clock-o {
      font-size: 15px;
      padding-right: 5px;
    }

    .fa-exchange {
      font-size: 15px;
      padding: 0 10px;
    }
  }
}