.auth{
    background:#fff url('/img/bg-auth.jpg') fixed no-repeat center center;
    background-size: cover;

    @media screen and (orientation:portrait) {
        background-position: 70% 50%;
    }

    #app{
        padding: 110px 0 0 !important;
        min-height: 100%;
        display: table;
        table-layout: fixed;
        width: 100%;
        height: 100%;

        // @media (max-width: 767px) {
        //     padding-top: 60px !important;
        // }
    }

    header.header{
        background: $brand-primary;

        .container-fluid {
            max-width: 1230px;
        }

        .header-table{
            background: none;
            border: 0;
        }
    }

    a{
        color: $brand-primary;

        &:hover,
        &:focus{
            color: $brand-primary;
            text-decoration: underline;
        }
    }

    .link-back-btn {
        height: 40px;
        line-height: 1.7;
        font-size: 16px;
        font-weight: 500;
        color: #303743;
        text-decoration: none;

        &:hover {
            color: #303743;
            text-decoration: none;
        }
    }
}

/***** footer *****/
.auth-footer {
    display: table-footer-group;
    overflow: hidden;

    &-row {
        display: table-row;
        height: 1%;
    }
    
    &-cell {
        display: table-cell;
        vertical-align: bottom;
        text-align: center;
        padding-top: 15px;
        padding-bottom: 15px;
    }
}

.auth-form{
    background: rgba(255,255,255,0.8);
    margin: 30px auto;
    padding: 20px 35px;
    max-width: 580px;
    border-radius: 3px;

    @media (max-width: 767px) {
        padding: 15px;
        margin: 0 auto
    }

    &-title{
        text-align: center;
        padding-top: 15px;
        margin-bottom: 20px;

        h2{
            margin: 0 0 5px;
            font-weight: 300;

            b{
                font-weight: 500;
            }
        }
    }

    &-logo{
        img{
            max-height: 40px;
            width: auto;
            display: block;
            margin: 0 auto 20px;
        }      
    }

    .form-group{
        margin-bottom: 15px;
    }

    .form-control{
        background: #fff;
        border: 1px solid #d6d8da;
        border-radius: 3px;
        height: 40px;
        padding: 10px 15px;
        font-size: 14px;
        line-height: 20px;
        color: #363d48;
    }

    .input-holder{
        position: relative;

        .fa{
            position: absolute;
            top: 5px;
            left: 10px;
            line-height: 28px;
            text-align: center;
            font-size: 16px;
            width: 20px;
            color: #c4c4c4;

            &.fa-lock{
                font-size: 20px;
            }
        }

        .form-control{
            padding-left: 40px;
        }
    }

    &-submit{
        background-color: $brand-primary;
        height: 40px;
        border-color: $brand-primary;
        font-size: 16px;
        font-weight: 500;

        &:hover{
            background-color: $brand-primary-2;
        }

        @media (max-width: 480px) {
            font-size: 14px;
        }
    }

    [type="checkbox"].custom:not(:checked) + label,
    [type="checkbox"].custom:checked + label,
    [type="radio"].custom:not(:checked) + label,
    [type="radio"].custom:checked + label{
        margin-bottom: 0;
    }
    .intl-tel-input .flag-container{
        height: 40px;
    }

    .check-item label{
        font-size: 13px;
    }

    &-block{
        input,
        textarea,
        select,
        input:-webkit-autofill,
        textarea:-webkit-autofill,
        select:-webkit-autofill {
            -webkit-box-shadow: 0 0 0 80px white inset !important;
        }
    }

    .chosen-container-single .chosen-single{
        height: 40px;
        padding: 10px 15px;
        font-size: 14px;
        line-height: 20px;

        > div{
            top: 3px;
        }

        &:hover{
            text-decoration: none;
            color: #363d48;
        }
    }
}

.auth-admin{
    background: #ccc;

    #bg{
        position: absolute;
        top:0;
        left:0;
        height: 100%;
        width:100%;
        overflow: hidden;
    }

    .auth-form,
    .auth-footer .container{
        position: relative;
    }
}