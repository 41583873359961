// reset list styles
@mixin reset-list-styles() {
    list-style: none;
    margin: 0;
    padding: 0;
}

@mixin button-variant($color, $background, $border, $background-hover) {
  color: $color;
  background-color: $background;
  border-color: $border;

  &:hover,
  &:focus,
  &.focus,
  &:active,
  &.active {
    color: $color;
    background-color: $background-hover;
    border-color: $border;
  }
}

@mixin button-inverse-variant($color, $btn-inverse-bg, $btn-inverse-hover-bg) {
  color: $color;
  background-color: $btn-inverse-bg;
  border-color: $color;

  &:hover,
  &:focus,
  &.focus,
  &:active,
  &.active {
    color: #fff;
    background-color: $btn-inverse-hover-bg;
    border-color: $color;
  }
}