.btn{
    text-transform: uppercase;
    white-space: normal;
    font:400 13px/20px $font-family-sans-serif;
    border-radius: 3px;
    box-shadow: none;
    min-height: 32px;
    padding: 5px 15px;
    transition: all .25s;

    &:active,
    &.active {
      box-shadow: none;
    }

  // &:hover,
  // &:focus,
  // &.focus {
  //   color: $btn-default-color;
  //   text-decoration: none;
  // }
}

.btn-link{
    text-transform: none;
    font-weight: 400;

    &:hover,
    &:focus,
    &.focus {
      color: $link-hover-color;
      text-decoration: none;
    }
}

.btn-default {
  @include button-variant($btn-default-color, $btn-default-bg, $btn-default-border, $btn-default-hover-bg);
}
.btn-primary {
  @include button-variant($btn-primary-color, $btn-primary-bg, $btn-primary-border, $btn-primary-hover-bg);
}
.btn-success {
  @include button-variant($btn-success-color, $btn-success-bg, $btn-success-border, $btn-success-hover-bg);
}
.btn-info {
  @include button-variant($btn-info-color, $btn-info-bg, $btn-info-border, $btn-info-hover-bg);
}
.btn-warning {
  @include button-variant($btn-warning-color, $btn-warning-bg, $btn-warning-border, $btn-warning-hover-bg);
}
.btn-danger {
  @include button-variant($btn-danger-color, $btn-danger-bg, $btn-danger-border, $btn-danger-hover-bg);
}
.btn-light {
  @include button-variant($btn-light-color, $btn-light-bg, $btn-light-border, $btn-light-hover-bg);
}

.btn-inverse{
  &.btn-primary {
    @include button-inverse-variant($btn-inverse-primary-color, $btn-inverse-bg, $btn-primary-hover-bg);
  }
  &.btn-success {
    @include button-inverse-variant($btn-inverse-success-color, $btn-inverse-bg, $btn-success-hover-bg);
  }
  &.btn-info {
    @include button-inverse-variant($btn-inverse-info-color, $btn-inverse-bg, $btn-info-hover-bg);
  }
  &.btn-warning {
    @include button-inverse-variant($btn-inverse-warning-color, $btn-inverse-bg, $btn-warning-hover-bg);
  }
  &.btn-danger {
    @include button-inverse-variant($btn-inverse-danger-color, $btn-inverse-bg, $btn-danger-hover-bg);
  }
}

.btn-lower{
  text-transform: none;
}