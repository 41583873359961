.user-details{
    &-header{
        border-bottom: 1px solid $border-color-light-grey;
        margin: 0 0 30px;
    }
    &-name h1{
        margin: 0 0 15px;
        font-size: 30px;
    }
    &-name i,
    &-name img{
        margin-right: 5px;
    }
    .iti-flag{
        display: inline-block;
        vetrical-align: middle;
        margin-right: 5px;
    }
    &-btns{
        text-align: right;
        @media (max-width: $screen-sm-min) {
            text-align: left;
        }
    }
    &-btns .btn{
        margin: 0 0 15px 15px;
        @media (max-width: $screen-sm-min) {
            margin: 0 15px 15px 0;
        }
    }
    &-block{

        &-title{
            text-transform: uppercase;
            margin: 0 0 25px;
            font-size: 18px;
            line-height: 1.2;
        }
    }
}

.personal-info > .row,
.sales-info > .row,
.addon-info > .row{
    margin-bottom: 10px;
}
.personal-info strong,
.sales-info strong{
    font-weight: 400;
}
.personal-info .dd{
    color: $text-light-color;
}
.addon-status.enabled{
    color: $brand-primary;
}
.addon-status.disabled{
    color: $text-light-color;
}
.addon-settings{
    float: right;
    margin-left: 15px;
}

.addon-info{
    .sub-setting-block{
        padding-top: 15px;
    }
    .addon-info-label{
        max-width: 220px;
        padding-top: 5px;
        padding-bottom: 15px;
    }
}

.admin-create-header {
    font-weight: 400;
}

.send-email-password {
    margin: 10px 0;

    .send-email-password-btn {
        width: 100%;
        padding: 10px;
        font-size: 14px;
    }
}

.admin-manual-password-form {
    display: none;
    margin: 10px 0 0;

    .manual-password-submit {
        width: 100%;
        padding: 10px;
        font-size: 14px;
    }
}

// .manual-links {
//     font-weight: bold;
//     padding: 0;
//     font-size: 16px;
//     color: #3B96D6;
// }

.contact-manuals {
    margin-bottom: 30px;
}
