.steps{
  @include reset-list-styles;
  display: flex;
  padding-right: 20px;
  overflow: hidden;
  margin: 0 0 30px;

  @media (max-width: $screen-sm-min) {
    display: block;
    padding-right: 0;
    border: 1px solid $border-color-grey;
  }

  > li{
    position: relative;
    flex: 1;

    &:first-child a{
      border-left: 1px solid $border-color-grey;
      padding-left: 10px;
    }

    &:last-child a{
        @media (max-width: $screen-sm-min) {
          border-bottom: 0;
        }
    }
  }

  a{
    border: 1px solid #d6d8da;
    border-left: 0;
    padding: 7px 10px 7px 30px;
    color: #8d8d8d;
    font-size: 14px;
    height: 100%;
    line-height: 20px;
    display: flex;
    align-items: center;
    position: relative;
    white-space: nowrap;

    @media (max-width: $screen-sm-min) {
      display: block;
      padding-right: 0;
      border: 0;
      border-bottom: 1px solid $border-color-grey;
      padding: 7px 15px !important;
    }

    &:after,
    &:before {
      left: 100%;
      top: 50%;
      border: solid transparent;
      content: " ";
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;

      @media (max-width: $screen-sm-min) {
        left: auto;
        right: 16px;
        top: 100%;
      }
    }

    &:after {
      border-color: rgba(255, 255, 255, 0);
      border-left-color: #fff;
      border-width: 18px 0 18px 16px;
      margin-top: -18px;
      z-index: 10;
      @media (max-width: $screen-sm-min) {
        margin-top: 0;
        border-width: 16px 18px 0;
        border-left-color: rgba(255, 255, 255, 0);
        border-top-color: #fff;
      }
    }
    &:before {
      border-color: rgba(214, 216, 218, 0);
      border-left-color: #d6d8da;
      border-width: 19px 0 19px 17px;
      margin-top: -19px;
      z-index: 9;

      @media (max-width: $screen-sm-min) {
        margin-top: 0;
        border-width: 17px 19px 0;
        border-left-color: rgba(255, 255, 255, 0);
        border-top-color: #d6d8da;
        right: 15px;
      }
    }
  }

  a:hover{
    color: #333;
    background: #dedede;

    &:after{
      border-left-color: #dedede;
      @media (max-width: $screen-sm-min) {
        border-left-color: rgba(255, 255, 255, 0);
        border-top-color: #dedede;
      }
    }
  }
  .active a{
    color: #fff;
    background: $brand-primary;

    &:after{
      border-left-color: $brand-primary;
      @media (max-width: $screen-sm-min) {
        border-left-color: rgba(255, 255, 255, 0);
        border-top-color: $brand-primary;
      }
    }
  }

  &-num{
    font-size: 18px;
    font-weight: 400;
    margin-right: 10px;
    display: inline-block;
    vertical-align: middle;
  }
}