.sidebar {
    position: fixed;
    z-index: 1000;
    top: 0;
    min-height: 100%;
    left: 0;
    right: auto;
    width: 300px;
    background: #f5f5f5;
    border-right: 1px solid $border-color-grey;

    @media (max-width: $screen-lg-min) {
        width: 260px;
    }

    @media (max-width: $screen-md-min) {
        left: 100%;
        border-left: 1px solid $border-color-grey;
        border-right: 0;

        transition: transform 0.4s ease-in-out;
        transform: translateX(260px);
    }

    &-wrap{
        padding: 90px 15px 50px;
        height: 100%;
        overflow-y: auto;

        @media (max-width: $screen-md-min) {
            padding-top: 30px;
        }
    }

    @media (min-width: $screen-sm-max) {
        &.has-info{
            .sidebar-wrap{
                padding-top: 140px;
            }
        }
    }

    &-logo{
        max-width: 200px;
        margin: 0 auto 50px;

        img{
            vertical-align: middle;
        }
    }

    &-footer{
        text-align: center;
        font-size: 12px;
        font-weight: 300;
        position: absolute;
        bottom: 0;
        width: 100%;
        left: 0;
        padding: 20px;
        background: linear-gradient(to bottom, rgba(243,243,243,0) 0%,rgba(243,243,243,1) 30%,rgba(243,243,243,1) 100%);
        color: #888;
        
        &-logo{
            display: inline-block;
            vertical-align: baseline;
        }

        p{
            margin: 0;
        }
    }

    &-nav{
        position: relative;
        padding-top: 20px;
        padding-bottom: 25px;
        border-top: 1px solid $border-color-grey;

        .title{
            position: absolute;
            left: 0;
            width: 100%;
            top: -11px;
            color: #888;

            &[data-toggle="collapse"]{
                cursor: pointer;
            }

            &[aria-expanded="true"] .title-ico:before{
                content: "\f077";
            }
        }

        .title-txt{
            background: #f3f3f3;
            padding: 0 15px 0 0;
            font-size: 14px;
            line-height: 20px;
            font-weight: 400;
            text-transform: uppercase;
            float: left;
        }

        .title-ico{
            float: right;
            background: #f3f3f3;
            padding: 0 0 0 15px;
            float: right;
            line-height: 20px;
        }

        .add-txt{
            font-size: 85%;
            margin-left: 10px;
        }
    }
}