.chart-wrap{
  //  overflow: hidden;
}
.chart{
    &-title{
        text-align: center;
        border-bottom: 1px solid $border-color-grey;
        display: table;
        width: 100%;
        margin-bottom: 30px;

        &-holder{
            display: table-cell;
            vertical-align: middle;
            padding: 10px 15px;
            height: 45px;
        }

        h4{
            margin: 0;
        }
    }
}
.chart-box{
    padding: 0 15px 15px;
    position: relative;

    @media (max-width: $screen-sm-min) {
        padding: 0 0 30px;
    }

    &:before{
        position: absolute;
        top: 65px;
        left: -15px;
        content: "";
        width: 1px;
        height: 165px;
        background: $border-color-grey;

        @media (max-width: $screen-sm-min) {
            display: none;
        }
    }
}
.chart-col:first-child{
    .chart-box:before{
        display: none;
    }
}

.chart-col:last-child{
    .chart-box:before{
        @media (max-width: $screen-lg-min) {
            display: none;
        }
    }
}

.chart-info{
    text-align: center;
    margin: 0 0 15px;

    .val{
        font-size: 28px;
        font-weight: 400;
        display: block;
        margin: 0 0 10px;
    }

    i{
        font-size: 35px;
    }
}
.chart-tabs{
    margin: 0 0 15px;
}
.chart-tabs.tabset > li{
    width: 50%;

}
.chart-tabs.tabset > li > .btn{
    text-transform: none;
}
.chart-holder{
    img{
        margin: 0 auto;
        display: block;
        max-width: 100%;
        height: auto;
    }
}

.grey{
    .chart-info{
        color: #8b8c8b;
    }
}
.light-green{
    .chart-info{
        color: #a4ce3b;
    }
}
.green{
    .chart-info{
        color: #4ab648;
    }
}
.blue{
    .chart-info{
        color: #3978be;
    }
}

.wide{
    .chart-controls{
        padding-top: 99px;
        @media (max-width: $screen-sm-min) {
            padding: 0 0 15px;
        }
    }
}

.stats{
    border: 1px solid $border-color-grey;
    border-radius: 3px;
    margin-bottom: 30px;
    overflow: hidden;

    &-row{
        display: flex;

        @media (max-width: $screen-md-min) {
            flex-direction: column;
        }
    }

    &-col{
        display: flex;
        flex-direction: column;
        margin-bottom: 15px;
    }

    &-content{
        flex: 1 0 auto;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 30px 15px;
        border-bottom: 1px solid $border-color-grey;
    }

    .chart-info{
        .val{
            font-size: 36px;
        }

        i{
            font-size: 40px;
        }
    }

    .chart-title{
        margin-bottom: 0;
    }

    &-footer{
        padding-bottom: 15px;
    }
}
.no_data-img{
    position: relative;
    text-align: center;

    img{
        max-width: 180px;
        height: auto;
        display: block;
        margin: 0 auto;
    }
}

.no_data-ttl{
    position: absolute;
    width: 100%;
    left: 0;
    top: 45%;
    color: #8b8c8b;
    font-size: 16px;
}
.chart-draw > div > div{
    margin: 0 auto;

    svg{
        overflow: visible !important;
    }
}

.charts{
    margin-bottom: 30px;

    &-row{
        display: flex;

        @media (max-width: $screen-md-min) {
            flex-direction: column;
        }
    }

    &-col{
        display: flex;
        flex-direction: column;
    }

    &-content{
        flex: 1 0 auto;
        display: flex;
        align-items: flex-end;
        padding: 15px 15px 30px;
        border: 1px solid $border-color-grey;
        border-radius: 3px 3px 0 0;
        position: relative;
        min-height: 157px;
        margin: 0 0 15px;

        &:after{
            position: absolute;
            bottom: 0;
            left: -1px;
            right: -1px;
            height: 10px;
            content: "";
            background: #3978be;
        }

        &.grey:after{
            background: #8b8c8b;
        }
        &.light-green:after{
            background: #a4ce3b;
        }
        &.green:after{
            background: #4ab648;
        }
        &.blue:after{
            background: #3978be;
        }
        &.light-blue:after{
            background: #95bbd7;
        }
        &.olive:after{
            background: #a4ce3b;
        }
        &.red:after{
            background: red;
        }
    }

    &-content.noflex{
        flex: none !important;
        align-items: flex-end;
        padding: 15px 15px 30px;
        border: 1px solid $border-color-grey;
        border-radius: 3px 3px 0 0;
        position: relative;
        min-height: 158px;
        margin: 0 0 15px;

        &:after{
            position: absolute;
            bottom: 0;
            left: -1px;
            right: -1px;
            height: 10px;
            content: "";
            background: #3978be;
        }

        &.grey:after{
            background: #8b8c8b;
        }
        &.light-green:after{
            background: #a4ce3b;
        }
        &.green:after{
            background: #4ab648;
        }
        &.blue:after{
            background: #3978be;
        }
        &.light-blue:after{
            background: #95bbd7;
        }
        &.olive:after{
            background: #a4ce3b;
        }
        &.red:after{
            background: red;
        }
    }

    &-icon{
        position: absolute;
        top: 10px;
        right: 10px;
        font-size: 36px;
        line-height: 40px;
        color: #363d48;
        text-align: center;
        width: 40px;
        z-index: 2;


    }

    &-txt{
        strong{
            font-size: 30px;
            font-weight: 400;
            display: block;

            @media (max-width: $screen-lg-min) {
                font-size: 28px;
            }
        }

        span{
            display: block;
        }
    }

    &-box{
        margin-bottom: 15px;
    }

    .no_data-img{
        margin: 0 auto;
        max-width: 200px;
        img{
            max-width: none;
            width: 100%;
            height: auto;
            display: block;
            margin: 0 auto;
        }
    }

    &-holder{
        width: 100%;
    }

    &-spinner{
        border: 1px solid #d6d8da;
        margin-bottom: 30px;
        border-radius: 3px;
        padding: 0 20px 20px;
    }
}

#total_words_translated{
    svg{
        height: 200px !important;
    }
}

.charts-index{
    .charts-col{
        margin-bottom: 30px;
    }

    .charts-content{
        margin-bottom: 95px;
    }

    .charts-col.single .charts-content{
        margin-bottom: 15px;
        flex: 1 0 auto;
    }

    .chart-info{
        position: absolute;
        left: 0;
        top: 100%;
        width: 100%;
        margin-top: 30px;
    }
}