.mce-notification-warning {
    display: none;
}

.template-view-container {

    #previewTemplate {
        margin-top: 10px;

        .modal-content {
            border-radius: 2px;
        }

        .modal-body {
            font-family: Arial, Helvetica, sans-serif;

            .template-context-container {
                width: 80%;
                margin: 0 auto;
                padding: 3%;
                //border-bottom: 1px solid #edeeef;
                // background-color: #fff;
                // box-shadow: 0 0 1px rgba(0,0,0,0.5);
                // border-top: 5px solid #72a0eb;
            }
        }
    }
}

.mail-cntr{
    width: 80%;
    margin: 0 auto;
    padding: 3%;
}

.template-head{
    background: #f5f8fa;
    border-bottom: 1px solid #edeeef;

    h2{
        font-weight: bold;
        color: #bbbfc3;
        margin: 0;
    }
}

.template-foot{
    font-size: 12px;
    color: #bbbfc3;
    border-top: 1px solid #edeeef;
    text-align: center;
    background: #f5f8fa;

    p{
        margin-bottom: 10px;
    }
}

#termBase-delete .modal-dialog {
    margin-top: 20vh;
}