.client-paginate-counter {
  line-height: 5;
}

.client-table-sorter {
  font-size: 12px;

  .actions {
    font-size: 7px;
  }
}

.client-log-container {

  .add-on-card-item {
    h3 {
      margin-bottom: 10px;
      font-weight: 500;
    }
  }

  .activated-add-on-list {
    padding-left: 0;
    list-style: none;
    font-size: 15px;
    font-weight: 400;

    li {
      margin-bottom: 3px;
    }
  }

  .user-details-name {
    .iti-flag {
      display: inline-block;
    }
  }
}

.static-container {
  .static-header {
    padding-top: 20px;
  }

  .tab-link {

    ul {
      margin-bottom: 0;
    }

    a.btn-block {
      font-size: 15px;
      padding: 10px 20px;

      i {
        font-size: 20px;
        padding-right: 5px;
      }
    }

    .export-link {
      padding: 10px 0;
    }
  }
}

.lang-picker-setting {
  h2 {
    padding-top: 20px;
  }
}

.home-sales-block {
  @media (min-width: 767px) {
    display: flex;

  }
}

.home-feedback-block {
  flex: 1 0 auto;
  display: flex;
  align-items: center;
  @media (max-width: 1024px) {
    font-size: 14px;
    img {
      height: 100px;
    }
  }

  font-size: 16px;
  font-weight: 500;
  color: grey;

  a {
    font-size: 14px;
    color: #2D96D9;
  }

}


.user-details-block, .organization-users-list {
  .linked-user {
    padding-left: 5px;
    color: #777;
    font-size: 14px;
  }
}

.client-info-row {
  .header-btns {
    float: left;
  }

  .client-info-name {
    color: #303743
  }

  .fa-chevron-down {
    color: #0700a4 !important;
  }
}