header.header{
    min-height: 60px;
    z-index: 1001;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;

    @media (max-width: $screen-md-min) {
        position: absolute;
    }
}
.header{
    &-table{
        width: 100%;
        display: table;
        background: $brand-primary;
    }

    &-cell {
        display: table-cell;
        vertical-align: middle;
        height: 60px;
        padding: 5px 45px;

        @media (max-width: $screen-lg-min) {
            padding: 5px 30px;
        }

        @media (max-width: $screen-md-min) {
            padding: 5px 15px;
        }
    }

    &-logo{
        width: 300px;
        text-align: center;
        padding: 5px 15px;

        @media (max-width: $screen-lg-min) {
            width: 260px;
        }

        @media (max-width: $screen-md-min) {
            width: auto;
            text-align: left;
        }
    }

    .logo{
        display: inline-block;
        vertical-align: middle;

        a {
            display: flex;
            align-items: center;
            justify-content: center;
        }

        img{
            max-height: 40px;
            width: auto;
            display: block;
        }

        &-img {
            max-width: 32px;
            display: block;
            height: auto;
        }

        &-separator {
            width: 1px;
            margin: 0 15px 0 10px;
            background: $brand-primary-3;
            height: 40px;
        }

        &-text {
            font-weight: bold;
            color: $brand-primary-3;
            font-size: 20px;
            line-height: 1;
            font-family: "Nexa Bold", "Roboto", Arial, sans-serif;
            white-space: nowrap;
        }
    }
}

.client-info{
    &-row{
        background: #fff;
        border-bottom: 1px solid #ccc;
        padding: 5px 45px 5px 345px;
        color: #303743;

        @media (max-width: $screen-lg-min) {
            padding: 5px 45px 5px 290px;
        }

        @media (max-width: $screen-md-min) {
            padding: 5px 15px;
        }
    }

    &-holder{
        display: flex;
        align-items: center;
    }

    &-logo{
        display: inline-block;
        vertical-align: middle;
   //     max-width: 40px;
        max-height: 40px;
        width: auto;
        margin-right: 15px;
        max-width: 100px;
    }

    &-name{
        font-weight: 400;
        text-transform: uppercase;
    }
}

.header-table{
    .client-info-name{
        color: #fff;
    }
}

.header-btns {
    @include reset-list-styles;

    float: right;
    color: #fff;

     > li {
        display: inline-block;
        vertical-align: middle;
        margin-left: 10px;
    }
}

.header-btns .btn {
    min-width: 130px;
    color: #fff;
    padding: 5px 15px;
    min-height: 0;
    border: 1px solid #ccc;
}
.header-btns .btn.no-border{
    border: 0;
}
.header-btns.links .btn {
    border: 0;
}
.header-btns .btn .fa{
    margin-left: 3px;
}

.header-btns{
    .btn{
        min-width: 0;
        border-color: #fff;
    }

    .btn-contact{
        margin-left: 30px;

        @media (max-width: $screen-lg-min) {
            margin-left: 15px;
        }
    }

    .btn-menu {
        display: none;
        vertical-align: middle;
        text-align: center;

        @media (max-width: $screen-md-min) {
            display: inline-block;
        }

        .btn{
            width: 36px;
            height: 36px;
            font-size: 20px;
            padding: 8px 5px;
        }

        .fa{
            margin: 0;
        }
    }
}
header.header{
    .navbar-toggle{
        float: none;
        display: none;
        vertical-align: middle;
        padding: 11px;
        margin: 0 0 0 15px;

        @media (max-width: $screen-sm-min) {
            display: inline-block;
        }

        .icon-bar{
            background: $btn-default-color;
        }
    }
}

.mobile-nav{
    background: #fff;
    border-top: 1px solid $border-color-light-grey;
    border-bottom: 1px solid $border-color-light-grey;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    display: none;

    &.open{
        display: block;
    }

    .login-box{
        padding: 20px 15px 15px;
        display: block;

        &-txt{
            display: block;
            margin: 0 0 15px;
        }
    }

    &-list{
        @include reset-list-styles;
        margin-bottom: 15px;
        font-size: 18px;
        line-height: 22px;
        font-weight: 300;
        text-transform: uppercase;

        a{
            color: #454545;
            display: block;
            padding: 5px 15px;
        }
    }
}

.langMenuBtn{
    white-space: nowrap;
    line-height: 20px;
    position: relative;

    &:before {
        right: 0;
        top: 0;
        width: 32px;
        height: 32px;
        font-size: 20px;
        line-height: 30px;
        position: absolute;
        text-align: center;
        color: #bfc0c1;
        display: inline-block;
        font-family: FontAwesome;
        font-size: inherit;
        text-rendering: auto;
        -webkit-font-smoothing: antialiased;
        content: "\f107";
    }

    img {
        margin: 0 5px 0 0;
        width: 28px;
        height: auto;
        display: inline-block;
        vertical-align: middle;
    }

    &:hover,
    &:focus{
        color: #363d48;
        text-decoration: none;
    }
}
.langMenu .langMenuList{
    background: #fff;
    width: 100%;
    position: absolute;
    left: 0;
    top: 100%;
    box-shadow: 0 5px 8px #bbb;
    z-index: 1010;
    border: 1px solid #eeeff0;
    padding: 5px;
    margin: 0;
}
.langMenuHeader {
    text-align: center;
    text-transform: uppercase;
    font-size: 18px;
    font-weight: 300;
    line-height: 1.5;
    padding: 0 0 15px;
    border-bottom: 1px solid #edeeef;
    margin: 0 0 15px;
}
.langMenuList{
    ul {
        overflow: hidden;
        text-align: left;
        list-style: none;
        margin: 0;
        padding: 0;
    }
    li {

    }
    a {
        color: #454545;
        display: block;
        padding: 10px;

        &:hover{
            background: #dedede;
            color: #303743;
            text-decoration: none;
        }
    }
    .selected a{
        color: #fff;
        background: #63a0e7;
        text-decoration: none;
    }
    img {
        margin: -3px 10px 0 0;
        width: 30px;
    }
}
.header{
    .langMenuBtn{
        &:before {
            display: none;
        }
    }
    .langMenu .langMenuList{
        background: #fff;
        border-radius: 0;
        border: 1px solid #edeeef;
        box-shadow: none;
        z-index: 5;
        left: 50%;
        top: 57px;
        width: 430px;
        margin: 0;
        margin-left: -215px;
        font-size: 16px;
        line-height: 22px;
        padding: 15px;

        @media (max-width: $screen-sm-min) {
            left: 0;
            right: auto;
            width: 100%;
            margin: 0;
            top: 100%;
        }
    }
    .langMenu.dropdown{
        @media (max-width: $screen-sm-min) {
            position: static;
        }
    }
    .langMenuList{
        ul {
            overflow: hidden;
            text-align: left;
            list-style: none;
            margin: 0;
            padding: 0;
        }
        li {
            display: inline-block;
            vertical-align: top;
            width: 50%;
            margin-right: -4px;

            @media (max-width: 480px) {
                width: 100%;
                margin: 0;
            }
        }
    }
}

.auth-menu{
    position: relative;
    font-size: 13px;
    max-width: 370px;

    @media (max-width: $screen-md-min) {
        max-width: 310px;
    }

    .btn{
        padding: 8px 36px 8px 10px;
        position: relative;
    }

    .btn i{
        color: $brand-primary-3;
        // font-size: 24px;
        margin: -18px 0 0;
        position: absolute;
        top: 50%;
        right: 0;
        width: 36px;
        height: 36px;
        line-height: 34px;
    }

    .dropdown-menu{
        box-shadow: none;
        border-color: $border-color-grey;
        padding: 0;
        margin: 0;

        ul{
            @include reset-list-styles;
            text-transform: uppercase;

            li{
                border-top: 1px solid $border-color-grey;

                &:first-child{
                    border-top: 0;
                }
            }

            a{
                display: block;
                padding: 7px 15px;
                color: $btn-default-color;

                &:hover,
                &:focus{
                    color: $btn-default-color;
                    text-decoration: none;
                    background: $btn-default-hover-bg;
                }
            }

            i{
                margin-right: 10px;
                font-size: 18px;
                vertical-align: middle;
            }
        }
    }
}

.header-light{
    background: #fff;
    border-bottom: 2px solid #ebebeb;
    font-family: ProximaNova;

    .d-flex{
        display: flex;
        align-items: center;
        height: 60px;
    }
    .logo-wrap{
        padding: 5px 30px;
        width: 300px;
        @media (max-width: $screen-lg-min) {
            width: 260px;
        }

        img{
            display: block;
            max-width: 200px;
            height: auto;
        } 
    }
    .header-client{
        padding: 5px 45px;
        justify-content: space-between;
        flex: 1 0 auto;

        @media (max-width: $screen-lg-min) {
            padding: 5px 30px;
        }
    }

    .header-client-nav{
        .title{
            cursor: pointer;
            color: #6E768F;

            &:hover{
                color: #303743;
            }
    
            .title-txt{
                font-weight: 600;
            }
            .title-ico{
                margin-left: 10px;
                vertical-align: middle;
            }
        }

        .dropdown-menu{
            left: 0;
            border: 0;
            max-width: 240px;
            margin: 10px 0 0;
            padding: 5px 0;
            border-radius: 5px;
            font-weight: 300;
            font-size: 14px;
            line-height: 18px;
            border: 1px solid #ebebeb;
            background: #fafafa;

            ul{
                list-style: none;
                margin: 0;
                padding: 0;

                a{
                    color: #465070;
                    transition: all .5s;
                    padding: 10px 15px;
                    display: block;
                    border-left: 3px solid #fafafa;

                    &:hover{
                        color: #303743;
                        text-decoration: none;
                        background: #fff;
                        border-left: 3px solid #fe119a;
                    }
                }
            }
        }
    }

    .btns-list{
        list-style: none;
        margin: 0;
        padding: 0;

        > li{
            display: inline-block;
            vertical-align: middle;
            margin-left: 15px;
        }
    }

    .btn-need-help{
        background-color: #FE119A;
        border-color: #FE119A;
        color: #fff;
        font-family: ProximaNova;
        font-size: 14px;
        line-height: 1.2;
        font-weight: 600;
        width: 184px;
        height: 40px;
        justify-content: center;
        align-items: center;
        display: flex;

        i{
            font-size: 24px;
            margin: 0 0 0 7px;
            vertical-align: middle;
        }

        &:hover{
            background-color: #fe6dc1;
            border-color: #FE119A;
            color: #fff;
        }
    }
}