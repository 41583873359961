.serv-item{
    position: relative;
    padding: 0 10px;
    margin: 0 -0.5px;
    float: left;
    width: 25%;
    display: flex;

    @media (max-width: $screen-md-min) {
        width: 50%;
    }
    @media (max-width: $screen-sm-min) {
        width: 100%;
        display: block;
    }

    &-row{
        margin-left: -10px;
        margin-right: -10px;
        display: flex;
        flex-wrap: wrap;
    }

    [type="checkbox"]:not(:checked),
    [type="checkbox"]:checked,
    [type="radio"]:not(:checked),
    [type="radio"]:checked {
        position: absolute;
        left: -9999px;
    }

    &-holder{
        display: block;
        font-size: 13px;
        line-height: 1.25;
        font-weight: 300;
        color: $text-light-color;
        border: 1px solid $border-color-grey;
        border-radius: 3px;
        padding: 15px;
        margin: 0 0 20px;
        text-align: center;
        cursor: pointer;

        &:hover,
        &.highlighted,
        &:focus{
            border: 2px solid $brand-primary;
            padding: 14px;
            color: $brand-primary;

            .serv-item{
                &-title,
                &-ico{
                    color: $brand-primary;
                }
            }
            .popover-text{
                color: $text-light-color;
            }
        }
    }

    [type="checkbox"]:checked + .serv-item-holder,
    [type="radio"]:checked + .serv-item-holder{
        border: 2px solid $brand-primary;
        padding: 14px;
        color: $brand-primary;

        .serv-item{
            &-title,
            &-ico{
                color: $brand-primary;
            }
        }
    }

    &-title{
        min-height: 40px;
        margin-bottom: 10px;

        span{
            display: block;
            font-size: 18px;
        }
    }

    &-ico{
        font-size: 24px;
        color: $text-color;
        margin: 0 0 15px;

        .fa-plus{
            font-size: 18px;
        }
    }

    p:last-child{
        margin: 0;
    }

    &.not-in-agreement {

        label {

            color: #486266;
            background-color: #d7edeb;
        }
    }
}

.five-items .serv-item{
    width: 20%;

    @media (max-width: $screen-lg-min) {
        width: 50%;
    }
    @media (max-width: $screen-sm-min) {
        width: 100%;
    }
}