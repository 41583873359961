.order-info{
    padding: 20px 0 0;
    border-top: 1px solid $border-color-light-grey;
}
.order-table{
    display: table;
    width: 100%;
    // max-width: 500px;
    margin: 0 0 20px;
}
.order-row{
    display: table-row;
}
.order-cell{
    display: table-cell;
    vertical-align: top;
    padding-bottom: 15px;
}
.order-total{
    font-size: 36px;
    line-height: 1.2;
    font-weight: 600;
    padding: 15px 0;
    border-bottom: 1px solid $border-color-light-grey;
}
.order-info h2{
    font-size: 20px;
    line-height: 1.2;
    color: #363d48;
    margin: 0 0 20px;
}
.order-info-label{
    width: 220px;
}
.order-info-txt{
    color: $text-light-color;
}
.order-btn-row .btn{
    min-width: 200px;
    margin: 0 15px 15px 0;
}
.order-btn-row.one-child .btn{
    min-width: 418px;
    margin: 0 15px 15px 0;
}
.order-table-list{
    @include reset-list-styles;
    max-width: 300px;

    > li{
        overflow: hidden;
        margin-bottom: 15px;

        &:last-child{
            margin-bottom: 0;
        }
    }

    a{
        margin-left: 15px;
        float: right;
    }
}

.order-info .select-custom-multiple{
    .chosen-container-multi .chosen-choices li.search-field{
        max-width: 300px;
    }
    .chosen-container .chosen-drop{
        max-width: 300px;
    }
}

.order-info .add-colleague {
    .chosen-container-multi .chosen-choices li.search-field {
        max-width: 400px;
    }

    .chosen-container .chosen-drop {
        max-width: 400px
    }
}

#order-summary {
    .summary-data {
        border: 1px solid #d6d8da;
        padding: 20px;
    }

    .summary-list {

        padding-left: 0;
        list-style: none;
        margin-bottom: 0;

        .summary-item {
            padding: 7px 0;

            ul.uploaded-files, ul#order-lang-to, ul.reference-files {
                list-style: none;
                padding: 0;

                li {
                    word-break: break-all;
                }
            }
        }
    }
}

.summary-order-quote {
    .orders-summary-list {
        list-style: none;
        padding-left: 0;

        .order-summary-item {
            margin: 10px 0;

            ul {
                list-style: none;
                padding: 0;
            }
        }
    }
}

@media (max-width:600px) {
    .order-info .select-custom-multiple{
        .chosen-container-multi .chosen-choices li.search-field{
            max-width: none;
        }
        .chosen-container .chosen-drop{
            max-width: none;
        }
    }

    .order-info .add-colleague {
        .chosen-container-multi .chosen-choices li.search-field {
            max-width: 400px;
        }

        .chosen-container .chosen-drop {
            max-width: 400px
        }
    }
}

@media (max-width:767px) {
    .order-total{
        font-size: 28px;
    }
    .order-table{
        max-width: none;
    }
}
@media (max-width:600px) {
    .order-table,
    .order-row,
    .order-cell{
        display: block;
    }
    .order-info-label{
        width: auto;
    }
    .order-contact-actions{
        display: block;
        width: 30%;
        float: left;
        text-align: right;
    }
    .order-total{
        font-size: 20px;
    }
    .order-btn-row .btn,
    .order-btn-row.one-child .btn{
        width: 100%;
        margin: 0 0 15px;
    }
}