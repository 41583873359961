html, body{
    height: 100%;
}

body{
    min-width: 320px;
    font-weight: $font-weight-base;
}

/* disabling autofill yellow background */

hr {
    margin-top: 15px;
    margin-bottom: 15px;
    border-color: $border-color-light-grey;
}

ul, ol {
    margin-top: 0;
    margin-bottom: 15px;
}

p{
    margin: 0 0 15px;
}

b, strong {
    font-weight: 500;
}
mark, .mark {
    background-color: #ff0;
}
h1, .h1{
    font-weight: 300;
    margin-top: 0;
    margin-bottom: 15px;
}
h2, .h2{
    font-weight: 400;
    margin-top: 0;
    margin-bottom: 15px;
}
h3, .h3{
    font-weight: 400;
    margin-top: 0;
    margin-bottom: 15px;
}
h4, .h4{
    font-weight: 400;
    margin-top: 0;
    margin-bottom: 15px;
}

.no-wrap{
    white-space: nowrap;
}

#app{
    padding-top: 60px;
    padding-left: 300px;
    transition: transform 0.4s ease-in-out;

    @media (max-width: $screen-lg-min) {
        padding-left: 260px;
    }

    @media (max-width: $screen-md-min) {
        padding-left: 0;
    }

    &.no-sidebar{

        padding-left:0;

        .content{
            margin:0 auto;
        }
    }
}

.menu-active{
    @media (max-width: $screen-md-min) {
        overflow: hidden;
    }

    #app{
        @media (max-width: $screen-md-min) {
            transform: translateX(-260px);
        }
    }

    .sidebar{
        @media (max-width: $screen-md-min) {
            transform: translateX(0);
        }
    }

    .content-overlay{
        @media (max-width: $screen-md-min) {
            position: absolute;
            background: rgba(0,0,0,0.3);
            z-index: 11;
            top: 0;
            left: 0;
            right: 0;
            height: 100vh;
        }
    }
}

.content {
    padding: 30px 45px 100px;
    margin: 0;
    max-width: 1230px;

    @media (max-width: $screen-lg-min) {
        padding: 30px 30px 100px;
    }

    @media (max-width: $screen-md-min) {
        padding: 30px 15px 100px;
    }

    .client-info{
        padding-bottom: 20px;
        margin-bottom: 20px;
        border-bottom: 1px solid $border-color-light-grey;
    }
}

.section-title{
    padding: 20px 0;

    h1{
        margin: 0;

        i{
            margin-right: 15px;
            vertical-align: middle;
        }
    }
}

.page-title{
    padding: 0 0 15px;
    text-align: center;

    h1{
        margin: 0 0 15px;
        font-weight: 400;

        i{
            margin-right: 15px;
        }
    }
}

.page-sub-title h2 {
    margin: 0 0 15px;
}

.page-heading {
    .title{
        margin: 0 0 15px;
        font-weight: 400;

        i{
            margin-right: 10px;
        }
    }

    .btn{
        float: right;
        margin: 0 0 15px;

        @media (max-width: $screen-xs-max) {
            float: none;
        }

        i{
            margin-left: 10px;
        }
    }
}

.progress{
    border: 1px solid $brand-primary;
    background: #cae4f4;
    border-radius: 0;
}

.breadcrumbs{
    ul{
        @include reset-list-styles();
        margin: 0 0 15px;

        li{
            display: inline-block;
            vertical-align: middle;

            &:before{
                content: "\f105";
                color: #75777b;
                margin: 0 10px;
                font-family: "FontAwesome";
                display: inline-block;
                vertical-align: baseline;
            }

            &:first-child:before{
                display: none;
            }
        }
    }
}

.dashboard{
    &-title{
        padding: 15px 0;

        h2{
            font-size: 20px;
            line-height: 1.2;
            margin: 0;
        }
    }
}

.intro{
    &-row{
        padding: 20px 0;
        border-bottom: 1px solid $border-color-light-grey;

        .container-fluid{
            max-width: 1170px;
        }
    }
    &-title{
        color: $headings-color;
        font-size: 22px;
        line-height: 1.2;
        font-weight: 300;
        margin: 5px 0;

        @media (max-width: $screen-sm-min) {
            text-align: center;
        }
    }
    &-btn{
        text-align: right;

        @media (max-width: $screen-sm-min) {
            text-align: center;
        }

        .btn{
            max-width: 280px;
            width: 100%;
        }
    }
}

.translate-form {
    // max-width: 560px;

    &-holder{
        max-width: 600px;

        @media (max-width: $screen-sm-min) {
            max-width: none;
        }
    }

    @media (max-width: $screen-md-min) {
        max-width: none;
    }

    .form-group {
        color: $text-light-color;
    }

    .form-control-lang {
        position: relative;

        .multiselect{
            position: static;
        }
    }
    .form-control-lang-col {
        position: static;

        &:first-child{
            @media (max-width: $screen-sm-min) {
                margin-bottom: 15px;
            }
        }
    }

    .check-item + .check-item{
        padding-top: 15px;
    }

    .hint{
        margin-top: 10px;
    }

    .btn-row .btn{
        margin-bottom: 15px;
    }

    &-btns{
        .btn{
            min-width: 140px;
            margin-bottom: 15px;
        }
    }

    /* TODO remove styles for datepicker */
    .datepicker{
        color: $text-color;

        table tr td.active.active,
        table tr td.active.disabled,
        table tr td.active.disabled.active,
        table tr td.active.disabled.disabled,
        table tr td.active.disabled:active,
        table tr td.active.disabled:hover,
        table tr td.active.disabled:hover.active,
        table tr td.active.disabled:hover.disabled,
        table tr td.active.disabled:hover:active,
        table tr td.active.disabled:hover:hover,
        table tr td.active.disabled:hover[disabled],
        table tr td.active.disabled[disabled],
        table tr td.active:active,
        table tr td.active:hover,
        table tr td.active:hover.active,
        table tr td.active:hover.disabled,
        table tr td.active:hover:active,
        table tr td.active:hover:hover,
        table tr td.active:hover[disabled],
        table tr td.active[disabled]{
            background: $brand-primary;
        }
        table tr td.today{
            background: #fff;

            &:before{
                border-bottom-color: $brand-primary;
            }

            &.active,
            &:hover,
            &:hover:hover{
                background: $btn-primary-hover-bg;
                color: #fff;
            }
        }

        table tr td.day.focused,
        table tr td.day:hover {
            background: $btn-primary-hover-bg;
            color: #fff;
        }
    }

    .form-dropdown.pt-15{
        padding-top: 15px;
    }

    .btn-custom-wide{
        min-width: 230px;
    }
}

.translate-type-list{
    @include reset-list-styles;

    margin: 0 0 15px;
    padding: 0 0 15px;
    font-size: 16px;
    line-height: 20px;
    border-bottom: 1px solid $border-color-grey;
    color: $color-dark-grey;

    &:last-child{
        margin: 0;
        padding: 0;
        border-bottom: 0;
    }

    .title{
        font-size: 18px;
        line-height: 22px;
        padding: 6px 10px;

        &-premium .icon:before{
            content: "\e922";
        }
        &-review .icon:before{
            content: "\e906";
        }
    }

    a{
        color: $color-dark-grey;
        display: block;
        padding: 7px 10px;

        &:hover{
            color: #fff;
            background: $brand-primary;
            text-decoration: none;
        }
    }
}

.nav-tabs {
    margin-bottom: -1px;

    > li {

        > a {
            line-height: 1.6;
            border-bottom: 1px solid $border-color-grey;

            &:hover {
                background: $bg-color-grey;
            }
        }
    }
}

.modal-search{
    font-size: 16px;

    &-field {
        position: relative;

        &:before{
            content: "\e903";
            display: inline-block;
            font-family: 'icomoon';
            font-size: inherit;
            text-rendering: auto;
            font-size: 20px;
            line-height: 34px;
            width:35px;
            text-align: center;
            position: absolute;
            top: 0;
            left: 0;
            color: #c2c4c8;
        }
    }

    .form-dropdown{
        padding: 20px 0;

        &-content{
            padding: 0;
        }
    }

    .form-control{
        border-width: 0 0 1px;
        padding-left: 40px;
        color: #c2c4c8;
        font-style: italic;
    }

    .files-list{
        border-bottom: 1px solid #edeeef;
        padding-top: 20px;

        li{
            margin: 0;
            padding: 10px 30px;
            border-top: 1px solid #edeeef;

            &:before{
                top: 9px;
            }
        }
        &-link{
            top: 9px;
        }
    }
}

.quick-section{
    margin: 0 0 30px;

    p{
        color: $text-light-color;
        margin: 0 0 30px;
    }
}
.quick-alert{
    border: 1px solid $border-color-grey;
    background: $bg-color-grey;
    text-align: center;
    font-weight: 400;
    padding: 15px;

    .status-val{
        display: inline-block;
        vertical-align: middle;
        width: 24px;
        height: 24px;
        border-radius: 50%;
    }
    .online .status-val{
        background: $brand-success;
    }
    .offline .status-val{
        background: #ccc;
    }
}

.pagination-nav{
    .total{
        line-height: 20px;
        padding: 5px 0;
        margin: 0 0 15px;
    }
    .pagination{
        float: right;
        margin: 0 0 15px;
        border-radius: 0;

        @media (max-width: $screen-xs-max) {
            float: none;
        }
    }
}

.mem-search{
    &-header{
        padding-top: 15px;
        padding-bottom: 15px;
    }

    &-form{
        position: relative;
        padding-right: 32px;

        .btn{
            position: absolute;
            top: 0;
            right: 0;
            cursor: default;
            padding: 3px 5px;
            width: 32px;
        }
    }

    &-table{
        table{
            // table-layout: fixed;

            .actions{
                width: 80px;
            }
        }

        //td{
        //    vertical-align: top !important;
        //}

        .highlight{
            background: $brand-primary;
            color: #fff;
        }
    }

    &-langs{
        min-width: 200px;
        overflow: hidden;
        padding: 5px 0;

        .termbase {
            float: left;
            margin: 0 7px 0 0;
            position: relative;

            &.has-comment:after {

                content: "";
                display: block;
                position: absolute;
                top: -5px;
                right: -5px;
                border-radius: 5px;
                width: 10px;
                height: 10px;
                background-color: #ff626d;
            }
        }

        .inline-editable-container,
        .string-text {
            overflow: hidden;
            display: block;
        }
    }

    .input-text{
        width:120px;
        float:left;
        position: relative;

        .form-control{
            padding-right: 30px;
        }

        .icon-small{
            background: none;
            padding: 0;
            margin: 0;
            position: absolute;
            top: 7px;
            right: 7px;
        }
    }

    .popover{
        text-align: left;

        p:last-child{
            margin: 0;
        }

        dl{
            margin: 0;
        }
    }

    .chosen-search-input{
        display: block;
        width: 100%;
        height: 36px;
        padding: 7px 15px;
        font-size: 16px;
        line-height: 1.25;
        color: #363d48;
        background-color: #fff;
        background-image: none;
        border: 1px solid #d6d8da;
        border-radius: 0;
        outline: none;
        margin: 0 0 15px;
    }

    .chosen-drop{
        max-height: 300px;
        overflow-y: auto;
    }

    .red-color {
        color: #ff0000;
    }

    .switcher-list .switcher + .switcher{
        margin-top: 7px;
    }

    .switcher-list .lang-item{
        font-size: 12px;
        line-height: 20px;
    }
}

.actions{
    &-btn{
        display: inline-block;
        vertical-align: middle;
        width: 32px;
        height: 32px;
        border: 0;
        background: none;
        box-shadow: none;
        text-align: center;
        font-size: 20px;
        line-height: 31px;
        padding: 0;
        margin: 0;
    }
}

.lang-item{
    // cursor: move;
    background: #dedede;
    padding: 3px 7px;
    display: inline-block;
    vertical-align: top;
    border-radius: 3px;
    margin: 2px 0;
    min-width: 45px;
    text-align: center;
    color: #363d48;

    a{
        color: #363d48;
    }
}

.tabset{
    margin-bottom: 20px;

    > li{
        width: 115px;
        float: left;
        margin-right: -1px;
        position: relative;

        .btn{
            padding: 5px 15px;

            &:hover,
            &:focus{
                border-color: $brand-primary;
                // background: #fff;
            }
        }


        &.active{
            z-index: 2;

            .btn{
                border-color: $brand-primary;
                color: $brand-primary;
            }
        }
    }
}
.table-controls{
    padding-top: 20px;
    padding-bottom: 10px;
}

.google-visualization-tooltip-item {
    white-space: nowrap;
}

.preselect{
    text-align: right;
}

.translate-form .switcher{
    .switcher{
        &-val{
            text-transform: none;
            color: $text-color;
        }
        &-val-grey{
            color: #b8b8b8;
            text-transform: uppercase;
            font-weight: normal;
            line-height: 30px;
            display: inline-block;
            vertical-align: middle;
        }
        &-item{
            margin-right: 0;
        }
    }
    .dz-filename span{
        display: block;
        margin: 0 0 15px;
    }
}

.add-switchers{
    max-width: 80%;
    @media (max-width: $screen-sm-min) {
        max-width: none;
    }

    .switcher .switcher-val{
        min-width: 130px;
    }
}

.is-online{
    color: #399300;
}

.is-offline{
    color: #ff626d;
}

.words-counter{
    .number{
        color: #399300;
    }

    .number.is-extra{
        color: #ff626d;
    }
}
.monospace{
    font-family: $font-family-monospace;
    letter-spacing: -1px;
}
#work_files_list p{
    margin-top: 10px;
}
.link-download{
    font-size: 18px;
    display: inline-block;
    vertical-align: middle;
    margin: -3px 0;
}

.dz-filename-remove{
    font-size: 24px;
    line-height: 1;
    display: inline-block;
    vertical-align: middle;
    margin: 0 0 2px 10px;
    //color: #b8b8b8;
}

.ticket-filename-remove{
    font-size: 24px;
    line-height: 1;
    display: inline-block;
    vertical-align: middle;
    margin: 0 0 2px 10px;
    //color: #b8b8b8;
}

svg > g > g:last-child { pointer-events: none }

.contact-box {
    border: 1px solid $border-color-grey;
    margin-bottom: 30px;
    border-radius: 3px;

    &-title{
        margin: 15px 0;
        border-bottom: 1px solid $border-color-grey;
    }

    &-info{
        position: relative;
        padding-left: 30px;
        padding-bottom: 15px;

        .fa{
            position: absolute;
            left: 0;
            top: 0;
            font-size: 20px;
        }

        p{
            margin: 0 0 5px;
        }

        .name{
            font-weight: 400;
        }

        .post{
            text-transform: uppercase;
            color: $text-light-color;
        }
    }
}

.popover-text{
    color: $brand-primary;
    cursor: pointer;
}

.popover-text:hover{
    text-decoration: underline;
}

.popover-content {
    color: #363d48;
    font-weight: 300;
}
.popover-info{
    width: 400px;
}

.fake-table{
    width: 100%;
    overflow: hidden;
    margin: 0 0 20px;
    border: 1px solid #d6d8da;
    font-size: 13px;
    line-height: 1.4;

    &-head{
        padding: 8px;
        background: #dedede;
        color: #303743;
        font-weight: 500;
        height: 36px;
    }

    &-foot{
        padding: 8px;
        border-top: 1px solid #d6d8da;
        height: 36px;
    }

    &-body{
        display: flex;
        flex-direction: column;
    }

    &-col{
        padding: 8px;
        min-height: 36px;
        white-space: nowrap;

        &:first-child{

        }

        &:last-child{
            justify-content: flex-end;
            display: flex;
        }

        .project-ttl{
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }

    &-row{
        display: flex;

        &:nth-of-type(odd) {
            background-color: #fff;
        }
        &:nth-of-type(even) {
            background-color: #f7f7f7;
        }
    }
}

.settings{
    .heading{
        border-bottom: 1px solid #edeeef;
        margin: 0 0 20px;

        i{
            margin-right: 5px;
        }
    }
    .tabset {
        margin-bottom: 30px;
        border-bottom: 1px solid #edeeef;
    }
    .tabset > li{
        width: auto;
        margin-bottom: -1px;
    }
    .tabset > li .btn{
        i{
            margin-right: 5px;
        }
    }
    .tabset > li .btn:hover,
    .tabset > li .btn:focus{
        z-index: 2;
    }
    .open > .btn-default.dropdown-toggle{
        background: $brand-primary;
        border-color: $brand-primary;
        color: #fff;
    }
    .dropdown-menu {
        box-shadow: none;
        border-color: #d6d8da;
        padding: 0;
        margin: 0;
        list-style: none;
        text-transform: uppercase;

        li:first-child {
            border-top: 0;
        }

        a {
            display: block;
            padding: 7px 15px;
            color: #303743;

            &:hover,
            &:focus {
                color: #303743;
                text-decoration: none;
                background: #dedede;
            }
        }

        i{
            margin-right: 5px;
        }
        .active > a{
            color: #fff;
        }
        .active > a:hover,
        .active > a:focus{
            color: #303743;
        }
    }

    &-block{
        margin: 0 0 20px;
    }

    &-table{
        width: 100%;
        //   overflow: hidden;
        margin: 0 0 20px;

        &-row{
            display: flex;
            border-bottom: 1px solid #edeeef;

            &:nth-of-type(even){
                background: #f7f7f7;
            }
        }

        &-col{
            display: flex;
            flex: 1;
            padding: 8px;
            align-items: center;
            justify-content: center;

            &.col-1{
                justify-content: flex-start;
            }
        }

        h2{
            margin: 0;
        }

        [type="checkbox"].custom:not(:checked) + label,
        [type="checkbox"].custom:checked + label,
        [type="radio"].custom:not(:checked) + label,
        [type="radio"].custom:checked + label{
            margin: 0;
        }
    }

    .sub-setting-block{
        padding-top:15px;
    }
}

.icon-small{
    display: inline-block;
    vertical-align: middle;
    width: 18px;
    height: 18px;
    font-size: 14px;
    line-height: 1;
    border: 1px solid;
    border-radius: 3px;
    text-align: center;
    float:left;
    margin-right: 5px;
    margin-top: 7px;

    &.red{
        border-color: #f16647;
        color: #f16647;
    }

    &.green{
        border-color: #399300;
        color: #399300;
    }

    &.blue{
        border-color: $brand-primary;
        color: $brand-primary;
    }

    &:hover,
    &:focus{
        opacity: 0.7;
    }
}

.blue-border {
    border: 1px solid $brand-primary;
    padding: 10px 15px;
}

.dropzone-name-span{
    margin:5px;
    font-size: 16px;
}

.dropzone-remove-link{
    float: right;
    height: 10px;
}

.term-table {
    &-head{
        background: #f3f3f3;
        padding: 15px 120px 5px 40px;
        border: 1px solid #d6d8da;
        border-bottom: 0;
        position: relative;

        @media (max-width: $screen-xs-max) {
            padding: 15px 15px 5px;
        }

        .clearfix{
            @media (max-width: $screen-xs-max) {
                margin-left: -15px;
                margin-right: -15px;
            }
        }

        .form-dropdown-label{
            position: absolute;
            top: 15px;
            right: 15px;

            @media (max-width: $screen-xs-max) {
                position: static;
                margin-bottom: 10px;
            }
        }

        .form-group{
            margin-bottom: 10px;
        }
    }

    &-content{
        .table{
            table-layout: fixed;
        }
    }

    .table{
        td{
            padding: 8px 15px;
        }
        .col-num,
        .col-msg,
        .col-percent,
        .col-check,
        .col-info{
            width: 40px;
            text-align: center;
            vertical-align: middle;
            padding: 0;
        }

        .col-source,
        .col-target{
            @media (max-width: $screen-xs-max) {
                white-space: normal;
                word-break: break-word;
                padding: 8px 5px;
            }
        }

        .col-percent{
            color: #fff;
            background: $brand-primary;
        }

        .col-check .actions-btn{
            color: #ccc;
        }

        .ready{
            .col-check .actions-btn{
                color: $brand-success;
            }

            .col-percent{
                color: #fff;
                background: $brand-success;
            }
        }

        .info-row{
            background: #f3f3f3;
            td{
                border-bottom: 1px solid #d6d8da;
            }

            .actions-btn{
                font-size: 18px;
                outline: none;
            }
        }
    }
}

.spinner-icon {
    display: none;
    border: 6px solid #d6d8da;
    border-top: 6px solid $brand-primary;
    border-radius: 50%;
    width: 22px;
    height: 22px;
    animation: spin 2s linear infinite;
    margin: 0px auto;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.review{
    .head{
        font-size: 15px;
    }
    .text-light{
        color: $color-light-grey;
    }
    .panel-group{
        margin: 0 0 15px;
    }
    .panel-group .panel{
        border-radius: 0;
        border: 0;
        box-shadow: none;
    }
    .panel-default > .panel-heading{
        background: #ebf8fe;
        border-radius: 3px;
        margin-bottom: 5px;
        border: 1px solid #ddd;
        padding: 0 50px 0 5px;
        position: relative;
        color: #000;

        a{
            color: #000;
        }
    }
    .tbl-row{
        width: 100%;
        display: flex;
        align-items: center;
    }
    .tbl-cell{
        flex: 1;
        padding: 10px;
    }

    .arrow{
        position: absolute;
        font-size: 20px;
        line-height: 30px;
        top: 50%;
        margin-top: -15px;
        right: 10px;
        color: #495057;

        .fa:before{
            content: "\f078";
        }
    }
    .collapsed .arrow{
        .fa:before{
            content: "\f054";
        }
    }
    .btn-status{
        // max-width: 120px;
        //text-transform: none;
    }
    .panel-default > .panel-heading + .panel-collapse > .panel-body{
        border: 0;
        padding: 0;
    }
    .tbl-head{
        background: #dedede;
    }
    .panel-body > .tbl-row:nth-of-type(odd){
        background: #f7f7f7;
    }
    .panel-body .tbl-cell{
        flex: 1;
        padding: 10px 15px;
    }
    .panel-body .tbl-cell.act-cell{
        padding-right: 60px;
    }
    .btn-grey{
        color: #fff;
        background: #8d8d8d;
        border-color: #8d8d8d;

        &:hover,
        &:focus{
            background: #bbb;
        }
    }
    .tbl-cell.act-cell .btn{
        width: 200px;
        float: right;
    }
}

.edit-segment{
    color: #b8b8b8;

    h2{
        margin-bottom: 5px;
    }
}

.edit-segment-table {
    color: #363d48;
    border-right: 1px solid #d6d8da;
    border-top: 1px solid #d6d8da;
    margin-bottom: 30px;
    font-size: 14px;

    .edit-segment-row{
        display: flex;
        &.head{
            background: #f3f3f3;
        }
        &.body{
            min-height: 100px;
            align-items: stretch;
        }
    }
    .edit-segment-cell{
        position: relative;
        flex: 1;
        padding: 15px;
        border-left: 1px solid #d6d8da;
        border-bottom: 1px solid #d6d8da;

        &.num{
            width: 50px;
            flex: none;
        }

        &.target{
            background: #d4e5f2;
            padding-bottom: 40px;

            textarea{
                border: 0;
                padding: 0;
                border-radius: 0;
                background: none;
                resize: none;
                min-height: 75px;
                height: auto;
            }

            textarea[disabled]{
                background: none;
                cursor: default;
            }
        }
    }
}

.edit-segment-controls{
    position: absolute;
    bottom: 10px;
    right: 15px;

    .btn{
        border: 0;
        padding: 0;
        outline: none;
        min-height: 0;

        &.cancel{
            color: #f16647;

            &:hover{
                color: #f5947e;
            }
        }

        &:hover,
        &:focus{
            outline: none;
        }
    }

    .btn + .btn{
        margin-left: 10px;
    }

    i{
        margin-left: 5px;
        font-size: 18px;
    }
}

ins{
    background-color: #d4edda;
    padding: 3px;
}

del{
    background-color: #f8d7da;
    padding: 3px;
}
.mem-search-info.edited{
    position: relative;
}
.mem-search-info.edited:after{
    content: '';
    width: 10px;
    height: 10px;
    border-radius: 100%;
    background: #f16647;
    position: absolute;
    top: 5px;
    right: 5px;
}

.right-controls {

    display: flex;
    float: right;
    align-items: center;
    margin-top: 16px;
    margin-bottom: 15px;

    .green {
        color: green;
        font-weight: bold;
    }

    .confirmed-counter {

        margin-right: 10px;
    }
}

.zoom-control{
    max-width: 150px;

    .form-control{
        text-align: center;
    }
}

.fullscreen{
    max-width: 100% !important;
    padding: 10px !important;
}

//.table-striped tr td:last-of-type, .table-striped tr th:last-of-type{
//   text-align:center;
//}

.review-segment-row{
    cursor: pointer;
    &.selected{
        background-color: lightblue !important;
        &:hover{
            //  background-color: #48D1CC !important;
        }
    }
}

.tag{
    display: inline-block;
    vertical-align: middle;
    background: $brand-primary;
    color: #fff;
    padding: 0 5px;
    height: 18px;
    font-size: 12px;
    line-height: 18px;
    position: relative;

    &:after{
        top: 50%;
        border: solid transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
        border-color: rgba(45, 149, 212, 0);
        border-width: 9px 5px;
        margin-top: -9px;
    }

    &-start{
        border-radius: 3px 0 0 3px;
        margin-right: 7px;

        &:after{
            left: 100%;
            border-left-color: $brand-primary;
        }
    }

    &-end{
        border-radius: 0 3px 3px 0;
        margin-left: 7px;

        &:after{
            right: 100%;
            border-right-color: $brand-primary;
        }
    }
}

.inline-edit-btn,
.inline-submit{
    margin: 0 5px 0 0;
    padding: 0;
}

.evaluation-chart-row{
    display: flex;
    justify-content: stretch;

    @media (max-width: $screen-xs-max) {
        display: block;
    }
}
.evaluation-chart-col{
    display: flex;
    @media (max-width: $screen-xs-max) {
        display: block;
    }
}
.evaluation-chart-box{
    border: 1px solid #d6d8da;
    border-radius: 3px;
    padding: 15px;
    flex: 1;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    &.no-border{
        border: 0;
    }

    &.no-padding{
        padding: 0;
    }

    &.no-ovh{
        overflow: visible;
    }

    h1{
        font-weight: 400;
        font-size: 30px;
    }

    h2{
        margin: 0 0 -30px;
        padding-top: 15px;
        position: relative;
        z-index: 100;
    }

    .percentage{
        font-size: 24px;
        font-weight: 400;

        @media (max-width: $screen-lg-min) {
            //font-size: 20px;
        }
    }

    &.variation{
        h2{
            margin: 0 0 30px;
        }

        .embed-responsive{
            transform: scale(1.3);
        }
    }
}

.evaluation-chart-wrap{
    min-height: 1%;
    width: 100%;
}

.aside-box .embed-responsive-item{
    transform: scale(1.3);
}

.aside-box.order-summary{
    @media (max-width: $screen-sm-max) {
        height: auto !important;
    }
}

.static-loader {
    display: none;
    text-align: center;
}

.modal-preselect{
    .account-info-val{
        overflow: hidden;
    }
}

.row.help-heading {
    margin-top: 45px;
    margin-bottom: 30px;

    h2 {
        font-size: 22px;
        margin-bottom: 5px;
    }
}

.offices {
    margin: 0;
    padding: 0;
    border: none;

    .panel-heading {
        border-bottom: 1px solid #ccc;
        padding: 0;

        .panel-title {
            padding: 10px 15px;
            transition: transform 0.2s;
        }

        .text {
            color: #555;
        }

        i {
            float: right;
            font-size: 17px;
            font-weight: 900;
        }
    }

    .panel-title:hover {

        transform: translateX(5px);
    }
}

.try-chat {

    img {
        width: 100px;
        display: block;
        margin-left: 60px;
    }

    position: fixed;
    bottom: 25px;
    right: 75px;
    width: 155px;
    color: black;
    font-size: 16px;
    font-weight: 400;
}

.bug-search{
    .custom-row{
        margin: 0 -10px;

        > .form-group{
            padding-right: 10px;
            padding-left: 10px;
        }
    }
}

.static-loader {

    display: none;
    text-align: center;
}

.termbase_texts{
    &-item{
        padding-top: 10px;
    }
}
.termbase_update{
    &-item{
        padding-top: 10px;
    }

    .check-item label{
        margin: 0 !important;
    }
}

#quotes-section {

    th:last-child {

        text-align: center;
    }

    td:last-child {

        text-align: center;
    }
}

//custom alert

.alert.quotes-notification {
    display: none;
}

.alert {
    border-radius: 0;
    -webkit-border-radius: 0;
    box-shadow: 0 1px 2px rgba(0,0,0,0.11);
    display: table;
    width: 100%;
}

.alert-white {
    background-image: linear-gradient(to bottom, #fff, #f9f9f9);
    border-top-color: #d8d8d8;
    border-bottom-color: #bdbdbd;
    border-left-color: #cacaca;
    border-right-color: #cacaca;
    color: #404040;
    padding-left: 61px;
    position: relative;
}

.alert-white.rounded {
    border-radius: 3px;
    -webkit-border-radius: 3px;
}

.alert-white.rounded .icon {
    border-radius: 3px 0 0 3px;
    -webkit-border-radius: 3px 0 0 3px;
}

.alert-white .icon {
    text-align: center;
    width: 45px;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    border: 1px solid #bdbdbd;
    padding-top: 15px;
}


.alert-white .icon:after {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
    display: block;
    content: '';
    width: 10px;
    height: 10px;
    border: 1px solid #bdbdbd;
    position: absolute;
    border-left: 0;
    border-bottom: 0;
    top: 50%;
    right: -6px;
    margin-top: -3px;
    background: #fff;
}

.alert-white .icon i {
    font-size: 20px;
    color: #fff;
    left: 12px;
    margin-top: -10px;
    position: absolute;
    top: 50%;
}
/*============ colors ========*/
.alert-success {
    color: #3c763d;
    background-color: #dff0d8;
    border-color: #d6e9c6;
}

.alert-white.alert-success .icon,
.alert-white.alert-success .icon:after {
    border-color: #54a754;
    background: #60c060;
}
.wait-for-file{
    padding-top: 100px;
}

.status{
    &.in-progress,
    &.pending,
    &.sent{
        color: $brand-primary
    }
    &.delivered,
    &.closed,
    &.accepted,
    &.accepted_by_customer{
        color: #399300;
    }
    &.not-approved,
    &.splitted{
        color: #f9be4d;
    }
    &.declined,
    &.rejected {
        color: #ff626d;
    }
    &.opened{
        color: $text-color;
    }
    &.cancelled {
        color: $brand-danger;
    }
}
.slack-item{
    float: right;

    .img{
        float: right;
        margin-left: 15px;
        max-width:100px;
        height:auto;
    }

    .txt{
        overflow: hidden;
    }
}
.bug-search{
    .custom-row{
        margin: 0 -10px;

        > .form-group{
            padding-right: 10px;
            padding-left: 10px;
        }
    }
}

.orders-loader {

    display: inline-block;
    font-size: 10px;
}

.review-segment-row {

    td.col-source, td.col-target {
        position: relative;
        padding: 12px 15px;
    }

    .input-text {

        .inline-input {
            background-color: inherit;
            border: none;
            font-size: 12px;
            line-height: 1.7;
            min-height: 30px;
            height: 30px;
            padding:0;
            resize: none !important;
        }
    }

    ins {
        text-decoration: none;
    }
}
.validation-head{
    border: 1px solid $border-color-grey;
    padding:15px;
    margin: 0 0 30px;
    display: flex;
    align-items: center;

    .ico{
        font-size:40px;
        margin-right: 15px;
    }

    .txt{
        flex: 1;

        h2{
            margin: 0 0 5px;
        }
        p {
            margin: 0;
        }
    }
}

.validators-heading-row{
    h2,
    p{
        display: inline-block;
        vertical-align: middle;
    }
    h2{
        margin-right: 30px;
    }
}

.modal-footer{
    .btn{
        min-width: 90px;
    }
}

.repeated-file-list {
    padding: 5px 0 0 20px;
}

.manual{
    padding-bottom: 30px;
    
    .manual-head{
        padding-top: 30px;
        padding-bottom: 15px;
    }

    .manual-category{
        margin-bottom: 15px;
        
        &:nth-child(odd){
            clear: left;
        }
        &:nth-child(even){
            clear: right;
        }

        h3{
            padding-bottom: 5px;
            border-bottom: 1px solid #ccc;
        }

        ul{
            list-style: none;
            margin: 0 0 15px;
            padding: 0;

            li{
                margin-bottom: 5px;
            }
        }
    }
}
.lang-training-modal{
    .modal-header{
        border: 0;
    }

    .modal-body{


        @media (min-width: $screen-sm-max) {
            padding: 45px;
        }
    }

    .close{
        top: 15px;
        right: 15px;
        position: absolute;
        z-index: 5;
        cursor: pointer;
    }

    h1{
        font-size: 24px;
        line-height: 1.2;
        font-weight: 400;
        margin: 0 0 15px;
    }

    h2{
        font-size: 18px;
        line-height: 1.2;
        font-weight: 300;
        margin: 0 0 15px;
        color: #b8b8b8;
    }

    .lang-training-modal-img{
        margin-bottom: 30px;
        margin-top: 30px;
        max-width: 600px;
        width: 100%;
        height: auto;
    }
}

.lang-combinations{
    max-height: 200px;
    overflow-y: auto;
}

.paleturquoise-text {

    color: #addad4;
    font-weight: bold;
}

.agreement-hint {

    color: $gray-base;

    .actions-btn {
        outline: none;
        width:25px;
        height: 25px;
        font-size: 17px;
        vertical-align: baseline;
    }
}

.admin-agreements-hint.actions-btn  {

    font-size: 17px;
    outline: none;
}

.help-center-block{
    margin-bottom: 30px;

    .btn-primary{
        background-color: #fe119a;
        border-color: #fe119a;

        &:hover{
            background-color: #fe6dc1;
            border-color: #fe119a;
        }

        i{
            margin-left: 10px;
        }
    }
}

.admin-agreements-hint.actions-btn  {

    font-size: 17px;
    outline: none;
}

/* HIDE RADIO in Feedback modal */
.feedback{
    [type=radio] {
        position: absolute;
        opacity: 0;
        width: 0;
        height: 0;
    }

    /* IMAGE STYLES */
    [type=radio] + img {
        cursor: pointer;
        width: 64px;
        height: auto;
    }

    .feedback-label{
        display: inline-block;
        vertical-align: middle;
    }

    img.unchecked {
        display: block;
    }
    img.checked {
        display: none;
    }

    /* CHECKED STYLES */
    [type=radio]:checked + img.unchecked {
        display: none;
    }
    [type=radio]:checked + img + img.checked {
        display: block;
    }
}

#gallery, #layout-gallery {
    margin-top: 10px;
}

#gallery img, #layout-gallery img {
  width: 150px;
  margin-bottom: 10px;
  margin-right: 10px;
  vertical-align: middle;
}

#link-my-orders a {

    text-align: center;
    float: left;
    color: black;
    font-size: 14px;
    padding-bottom: 10px;

}

#link-my-orders a:hover {
    color: blue;
}

#feedback-button {
    background-color: #2D95D4;
    border: none;
    position: fixed;
    right: 0;
    top: 33%;
    transform-origin: bottom right;
    transform: rotate(-90deg);
    cursor: pointer;
}

#feedback-button-text {
    text-align: center;
    font-size: 16px;
    padding: 0 15px;
    color: #FFFFFF;
    transform: rotate(-180deg);
}

.hub-table-header tr th {
    background: #0700A4 !important;
    text-align: center;
    color: white !important;
}

.modal-body {
    max-height: calc(100vh - 210px);
    overflow-y: auto;
}

.mt-file-formats {

    text-decoration: underline;

    &:hover {
        cursor: pointer;
    }
}


.btn-link.vimeo-modal-btn {

    padding: 0;
    border: 0;
    font-weight: inherit;

    &:hover,
    &:focus{
        outline: none;
    }
}